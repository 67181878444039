// src/components/PrivateRequest.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import api from "../../services/api";

const PrivateRequest = () => {
  const [privateRequests, setPrivateRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // You can make limit dynamic if needed
  const [total, setTotal] = useState(0);

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchPrivateRequests = async () => {
      try {
        const response = await api.getPrivateRequests(page, limit);
        if (response.privateRequests) {
          setPrivateRequests(response.privateRequests);
          setTotal(response.total);
        } else {
          console.error("Error fetching private requests:", response.error);
        }
      } catch (error) {
        console.error("Error fetching private requests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrivateRequests();
  }, [page, limit]);

  const totalPages = Math.ceil(total / limit);

  // Function to handle message button click
  const handleMessageClick = (professionalId) => {
    navigate(`/profile/specificUser?professional_id=${professionalId}`);
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10 ">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">Private Request</h2>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <div className="space-y-6">
          {privateRequests.length === 0 ? (
            <p className="text-center">No private requests found.</p>
          ) : (
            privateRequests.map((request) => (
              <div key={request.id} className="p-4 border border-gray-300 rounded-lg">
                <h3 className="text-lg font-bold mb-2">{request.request}</h3>
                <div className="flex justify-between mb-4">
                  <div className="flex flex-col">
                    <span className="font-semibold">Category:</span>
                    <span>{request.category}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Price:</span>
                    <span>{request.price} Francs</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Address:</span>
                    <span>{request.address}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Time:</span>
                    <span>{request.time}</span>
                  </div>
                </div>
                <div className="flex gap-4">
                  {request.file1 && (
                    <img
                      src={`https://btobjob.urbancodez.com/requests/${request.file1}`}
                      alt="File 1"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  )}
                  {request.file2 && (
                    <img
                      src={`https://btobjob.urbancodez.com/requests/${request.file2}`}
                      alt="File 2"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  )}
                  {request.file3 && (
                    <img
                      src={`https://btobjob.urbancodez.com/requests/${request.file3}`}
                      alt="File 3"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  )}
                </div>

                <button
                  onClick={() => handleMessageClick(request.user_id)} // Pass professional ID
                  className="bg-blue-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
                >
                View Profile
                </button>
              </div>
            ))
          )}
          <div className="flex justify-center mt-6 space-x-2">
            <button 
              onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))}
              disabled={page === 1}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <button 
              onClick={() => setPage(prevPage => Math.min(prevPage + 1, totalPages))}
              disabled={page === totalPages}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivateRequest;
