import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://btobjob.urbancodez.com'; // Replace with your actual API URL

const AdsDetails = () => {
  const [ad, setAd] = useState(null); // State to hold ad details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();
  const location = useLocation();

  // Get ad_id from query params
  const query = new URLSearchParams(location.search);
  const adId = query.get('ad_id');

  useEffect(() => {
    const fetchAdDetails = async () => {
      if (!adId) {
        setError('No ad ID provided');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/fetch-ad-details.php`, {
          params: { ad_id: adId },
          withCredentials: true,
        });

        if (response.data && response.data.ad) {
          setAd(response.data.ad);
        } else {
          setError('Ad not found');
        }
      } catch (error) {
        console.error('Error fetching ad details:', error);
        setError('Error fetching ad details');
      } finally {
        setLoading(false);
      }
    };

    fetchAdDetails();
  }, [adId]);

  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Back
      </button>

      {/* Loading and Error States */}
      {loading && <p className="text-center text-gray-500">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      
      {/* Ad Details */}
      {ad && (
        <div className="bg-white shadow-md rounded-lg p-4">
          {/* Display multiple images in a grid layout */}
          {ad.images && ad.images.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
              {ad.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Ad Image ${index + 1}`}
                  className="w-full h-60 object-cover rounded-md"
                />
              ))}
            </div>
          )}
          <h1 className="text-2xl font-bold mb-2">{ad.title}</h1>
          <p className="text-lg text-gray-700 mb-4">{ad.description}</p>
          <p className="text-sm text-gray-500">Location: {ad.city}</p>
        </div>
      )}
    </div>
  );
};

export default AdsDetails;
