import './App.css';
import AppRouter from './config/Router';
import SubscriptionPage from './subscription/Subscription';
function App() {
  return (
   <>
   <AppRouter/>
   
   {/* <SubscriptionPage/> */}
   </>
  );
}

export default App;
