// ProfileEdit.jsx
import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { CiUser, CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { BiHide, BiShow } from "react-icons/bi"; // Import BiShow icon
import { useNavigate } from "react-router-dom";
import api from '../../services/api'; // Ensure this is correctly set up to handle API requests

const ProfileEdit = () => {
  const [toggle, setToggle] = useState(true);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();

  // State for user profile fields
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const NavigateToBack = () => {
    navigate(-1); // Navigates back one step in the history stack
  };

  useEffect(() => {
    // Fetch the session data to check if the user is a professional
    api.getUser().then((data) => {
      if (data.user) {
        // Initialize the form with the user's current data, ensuring we handle missing data gracefully
        setFullName(data.user.Name || ''); // Adjust field names to match backend
        setUsername(data.user.username || '');
        setPhoneNumber(data.user.Phone || '');
        setAddress(data.user.Address || '');
        setCity(data.user.city || '');
        setState(data.user.state || '');
        setZip(data.user.zip || '');
        setPassword(''); // Keep password field empty for security reasons
      }
    }).catch(error => {
      console.error('Error fetching session data:', error);
    });
  }, []);

  const handleUpdateProfile = () => {
    const formData = {
      fullName,
      username,
      phoneNumber,
      address,
      city,
      state,
      zip,
      password // Include password update if necessary
    };

    api.updateProfile(formData).then((response) => {
      if (response.status === 'success') {
        alert('Profile updated successfully');
        NavigateToBack();
      } else {
        alert('Failed to update profile: ' + response.message);
      }
    }).catch(error => {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    });
  };

  return (
    <div className="mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-16 md:px-0 px-2">
      <div className="flex flex-col">
        <div className="p-6 border-b-2 flex flex-col">
          <div onClick={NavigateToBack} className="flex items-center cursor-pointer">
            <FaArrowLeft className="h-6 w-6 pr-2" />
            <p className="text-xl font-semibold">Informations personnelles</p>
          </div>
        </div>

        <div className={`grid ${toggle ? "flex" : "hidden"} grid-cols-1 md:grid-cols-2 gap-2 mt-5 px-8 mb-20`}>
          {/* Render Input Fields */}
          <InputField
            value={fullName}
            onChange={setFullName}
            placeholder="Nom et prénom"
            Icon={CiUser}
          />
          <InputField
            value={username}
            onChange={setUsername}
            placeholder="Nom d'utilisateur"
            Icon={CiUser}
          />
          <InputField
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder="Numéro de téléphone"
            Icon={BsTelephone}
            type="tel"
          />
          <PasswordInput
            value={password}
            onChange={setPassword}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
          <InputField
            value={address}
            onChange={setAddress}
            placeholder="Address"
            Icon={CiLocationOn}
          />
          <InputField
            value={city}
            onChange={setCity}
            placeholder="City"
            Icon={CiLocationOn}
          />
          <InputField
            value={state}
            onChange={setState}
            placeholder="Country"
            Icon={CiLocationOn}
          />
          <InputField
            value={zip}
            onChange={setZip}
            placeholder="Zip Code"
            Icon={CiLocationOn}
          />
        </div>

        <div className="flex justify-end px-8 pb-10">
          <button
            onClick={handleUpdateProfile}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Mettre à jour
          </button>
        </div>
      </div>
    </div>
  );
};

// Helper Components
const InputField = ({ value, onChange, placeholder, Icon, type = "text" }) => (
  <div className="relative mb-3" data-twe-input-wrapper-init>
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type={type}
      className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear f peer-focus:text-primary motion-reduce:transition-none"
      placeholder={placeholder}
    />
    <Icon className="absolute right-2 top-2 text-gray-500" size={24} />
  </div>
);

const PasswordInput = ({ value, onChange, showPassword, setShowPassword }) => (
  <div className="relative mb-3" data-twe-input-wrapper-init>
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type={showPassword ? "text" : "password"} // Toggle password visibility
      className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear f peer-focus:text-primary motion-reduce:transition-none"
      placeholder="Password"
    />
    <div className="absolute right-2 top-2 flex items-center">
      {showPassword ? (
        <BiShow className="text-gray-500 cursor-pointer" size={24} onClick={() => setShowPassword(false)} /> // Show password icon
      ) : (
        <BiHide className="text-gray-500 cursor-pointer" size={24} onClick={() => setShowPassword(true)} /> // Hide password icon
      )}
    </div>
  </div>
);

export default ProfileEdit;
