import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import ProfileContent from "../../assests/profileContent.png";
import UserProfile from "../../assests/profileuser.png";
import { MdOutlineEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Star from "../../assests/Star.png";
import { IoMdContacts } from "react-icons/io";
import { BiMessageRoundedDots } from "react-icons/bi";
import { IoBriefcaseOutline } from "react-icons/io5";
import Photo1 from "../../assests/photos1.png";
import Photo2 from "../../assests/photos2.png";
import { FaEnvelope } from "react-icons/fa";
import { FaHammer } from "react-icons/fa6";
import { RiFlag2Fill } from "react-icons/ri";
import PostingPicture from "../../assests/postingpicture.png";
import Toggler from "../Toggler";
import { useNavigate } from "react-router-dom";
import ProfileShare from "./ProfileShare";
import { MdCancel, MdCurrencyFranc } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Rate2 from "./Rate2";
import axios from "axios";
import api from "../../services/api";
import PostModal from './PostModal';
import { AiOutlineShake } from "react-icons/ai";
// Import Leaflet CSS
import 'leaflet/dist/leaflet.css';

// Fix default icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});


const MainProfile = () => {
  // Share open/Close
  const [openShare, setOpenShare] = useState(false);
  const toggleShare = () => {
    setOpenShare((prevState) => !prevState);
  };
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rate, setRate] = useState({ minPrice: 0, maxPrice: 0 });
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [liked, setLiked] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  // Rate Open/Close
  const [openRate, setOpenRate] = useState(false);
  const toggleRate = () => {
    setOpenRate((prevState) => !prevState);
    setTimeout(() => {
      setOpenRate(false);
    }, 20000); // Adjust the delay as needed (5000ms = 5 seconds)
  };
  const navigateToReviews = () => {
    window.location.href = 'https://btobjob.urbancodez.com/all-reviews.php';
};
  // User data state
  const [user, setUser] = useState(null);
  const [position, setPosition] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [posts, setPosts] = useState([]);
  const [likesStatus, setLikesStatus] = useState({});
  const [ratingData, setRatingData] = useState({ avg_rating: 0, total_reviews: 0 });
  const [isProfessional, setIsProfessional] = useState(false);
  const [subscriptionExists, setSubscriptionExists] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const sessionData = await api.getSession();
        setUser(sessionData.user);

        if (sessionData.user) {
          const profileData = await api.fetchProfileById(sessionData.user.id);
          if (profileData.latitude && profileData.longitude) {
            setPosition([profileData.latitude, profileData.longitude]);
          }
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    fetchSession();
  }, []);
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await api.fetchProfile(); // Call the function properly
        setProfileData(response);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Call the function without any unnecessary conditions
    fetchProfileData();
  }, []); // Empty dependency array ensures it only runs once after the component mounts
  
  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get('https://btobjob.urbancodez.com/get-user-reviews.php', { withCredentials: true });
        const data = response.data;

        // Ensure avg_rating is a number and handle possible undefined/null
        const avgRating = data.avg_rating !== null && data.avg_rating !== undefined
          ? parseFloat(data.avg_rating)
          : 0;

        setRatingData({
          avg_rating: avgRating,
          total_reviews: data.total_reviews || 0
        });
      } catch (error) {
        console.error('Error fetching ratings:', error);
        setError('Error fetching ratings');
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, []);
  const avgRating = ratingData.avg_rating ? ratingData.avg_rating.toFixed(1) : '0.0';
  useEffect(() => {
    const fetchPhotos = async () => {
        try {
            const response = await axios.get('https://btobjob.urbancodez.com/get_photos.php', { withCredentials: true });
            if (Array.isArray(response.data)) {
                setPhotos(response.data.slice(0, 4)); // Limit to 4 photos
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error("Error fetching photos:", error);
            setError("Error fetching photos");
        } finally {
            setLoading(false);
        }
    };

    fetchPhotos();
}, []);

useEffect(() => {
  const getSubscription = async () => {
    try {
      const data = await api.getSubscription();
      setSubscriptionExists(data.subscription_exists);
      setIsProfessional(data.subscription_exists);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

  getSubscription();
}, []);
  const handleLike = async (postId) => {
    try {
      const response = await axios.post('https://btobjob.urbancodez.com/like-post.php', { postId }, { withCredentials: true });
      setLikesStatus(prevStatus => ({
        ...prevStatus,
        [postId]: response.data.liked
      }));
    } catch (error) {
      console.error('Error liking the post:', error);
    }
  };
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const profileData = await api.fetchProfileImage();
        const coverData = await api.fetchCoverImage();

        setProfileImagePreview(profileData.imageUrl);
        setCoverImagePreview(coverData.imageUrl);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get('https://btobjob.urbancodez.com/fetchRates.php', { withCredentials: true });
        setRate(response.data);
      } catch (error) {
        console.error('Error fetching rates:', error);
      }
    };

    fetchRates();
  }, []);
  const checkLikeStatus = async (postId) => {
    try {
      const response = await axios.post('https://btobjob.urbancodez.com/check-like.php', { postId }, { withCredentials: true });
      return response.data.liked;
    } catch (error) {
      console.error('Error checking like status:', error);
      return false;
    }
  };
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsData = await api.fetchPosts();
        setPosts(postsData);

        // Check like status for each post
        const likesPromises = postsData.map(post => checkLikeStatus(post.id));
        const likesResults = await Promise.all(likesPromises);
        
        const likesStatus = {};
        postsData.forEach((post, index) => {
          likesStatus[post.id] = likesResults[index];
        });
        setLikesStatus(likesStatus);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);
  const MapEvents = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setPosition([lat, lng]);
        if (user) {
          api.updateLocation(user.id, lat, lng)
            .then(response => {
              if (response.success) {
                alert("Location updated successfully!");
                console.log("Location updated successfully");
              } else {
                alert("Failed to update location.");
                console.error("Failed to update location");
              }
            })
            .catch(error => {
              alert("Error updating location.");
              console.error("Error updating location:", error);
            });
        }
      }
    });
    return null;
  };
  
  
  // Navigation
  const navigate = useNavigate();
  const NavigateToeditprofile = () => {
    navigate("/profile/editprofile");
  };

  const NavigateTochat = () => {
    navigate("/profile/chat");
  };

  const NavigateToallphotos = () => {
    navigate("/profile/allphotos");
  };

  const NavigateToAddOffer = () => {
    navigate("/profile/addoffer");
  };

  const NavigateToViewRequests = () => {
    navigate("/profile/requests");
  };

  const AddBio = () => {
    window.location.href = "https://btobjob.urbancodez.com/bio.php";
  };
  // Open service drop down
  const [isOpenService, setIsOpenService] = useState(false);

  const toggleDropdownForService = () => {
    setIsOpenService(prevState => !prevState);
  };

  const defaultPosition = [51.505, -0.09]; // Default position [latitude, longitude]

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng); // Update position on map click
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>
          <button onClick={saveLocation}>Save Location</button>
        </Popup>
      </Marker>
    );
  };

  const saveLocation = async () => {
    try {
      await api.saveUserLocation(position); // API call to save the location
      alert("Location saved!");
    } catch (error) {
      console.error('Error saving location:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Profile data not found.</div>;
  }
  return (
    <>
      <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
        <div>
        <img
            src={coverImagePreview || 'default-cover.jpg'}
            className="w-full object-cover h-48"
            alt="Cover"
          />
        </div>
        <div className="flex flex-col bg-white pb-6 relative">
          <div className="flex justify-between py-1 px-1 bg-white relative mb-4">
            <div className="flex relative ">
            <img
                src={profileImagePreview || 'default-profile.jpg'}
                className="w-24 h-24 object-cover rounded-full border-white"
                alt="Profile"
              />
              <div className="flex flex-col items-start">
              <span className="user-name">{profileData ? profileData.Name: 'Loading...'}</span>
              <span className="user-email">@{profileData.username}</span>
              <span className="user-email">{profileData.job_title}</span>
              </div>
            </div>
            <div className="flex flex-col absolute right-1">
              <div className="flex flex-row items-center justify-center gap-1">
                <button onClick={toggleShare} className="w-full cursor-pointer text-[10px] bg-white rounded-xl border-2 border-[#65A406] text-[#65A406]">
                  Partager
                </button>
                <button onClick={NavigateToeditprofile} className="sm:w-full cursor-pointer text-[10px] w-[140px] bg-white rounded-xl border-2 border-[#65A406] text-[#65A406]">
                  Editer
                </button>
                <button onClick={AddBio} className="sm:w-full cursor-pointer text-[10px] w-[140px] bg-white rounded-xl border-2 border-[#65A406] text-[#65A406]">
                biographie
                </button>
              </div>
              <Toggler />
            </div>
          </div>

          <div className="flex flex-col ml-">
            <div className="flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <CiLocationOn className="location-email-icon" />        <p>{profileData.Address}, {profileData.city}, {profileData.zip}</p>
            </div>
            <div className="flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <AiOutlineShake className="location-email-icon" />        <p>{profileData.Phone}</p>
            </div>
            <div className="flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <MdOutlineEmail className="location-email-icon" />
              <p>{profileData.Email}</p>
            </div> 
            <div className="flex flex-col items-start text-xs text-[#004225] sm:text-[15px] font-normal leading-5 ms-10 mt-3">
  <h6 className="mb-1">Bio</h6> {/* Added a margin-bottom for spacing */}
  <p className="font-bold">
    {profileData.bio}
  </p>
</div>

          </div>
        </div>

        {/* Conditional button rendering */}
        {/* <div className="flex justify-center mt-4">
          {user ? (
            user.professional ? (
              <div className="flex gap-2">
                <button onClick={NavigateToAddOffer} className="bg-blue-500 text-white px-4 py-2 rounded">
                  Add Offer
                </button>
                <button onClick={NavigateToViewRequests} className="bg-blue-500 text-white px-4 py-2 rounded">
                  View Requests
                </button>
              </div>
            ) : (
              <button onClick={NavigateToViewRequests} className="bg-blue-500 text-white px-4 py-2 rounded">
                View Requests
              </button>
            )
          ) : (
            'Loading...'
          )}
        </div> */}

        {/* Zain req */}
        {/* <div className="bg-white border-t-2">
        <ul className="purple-list">
          <li className="purple-listing">Aperçu</li>
          <li className="purple-listing">Demandes</li>
          <li className="purple-listing">Activité</li>
          <li className="purple-listing">Avis</li>
          <li className="purple-listing">Des photos</li>
        </ul>
      </div> */}
        {/* main div */}
 
        <div className="flex flex-col-reverse sm:flex-row justify-between mt-5 ">
          
          <div className="grid grid-cols-2 sm:flex sm:flex-col gap-2">
          {subscriptionExists && (
            <div
              className="flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
              onClick={navigateToReviews}
            >
              <p className="flex items-center gap-1">
                {avgRating} <img src={Star} className="w-3 h-3" alt="Star" />
              </p>
              <p className="div1-para-reviews">{ratingData.total_reviews} Reviews</p>
            </div>
         )}
      
      
            {/* 2nd */}
            {/* <div className="flex flex-col  justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 pl-4">
              <div className="flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoMdContacts className="w-5 h-5" />
                <div className="flex flex-col text-black">
                  <p className="font-semibold">38,47,154</p>
                  <p>Connexions</p>
                </div>
              </div>
              <div className="flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <BiMessageRoundedDots className="w-5 h-5" />
                <div className="flex flex-col text-black">
                  <p className="font-semibold">70%</p>
                  <p> Temps de réponse</p>
                </div>
              </div>
              <div className="flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoBriefcaseOutline className="w-5 h-5" />
                <div className="flex flex-col text-black">
                  <p className="font-semibold">3324</p>
                  <p>Emploi en direct</p>
                </div>
              </div>
            </div> */}
            {/* 3rd */}
            {subscriptionExists&& (
            <div className="flex flex-col justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 px-2 py-2">
            <div className="flex justify-between">
                <p className="photos-para">Photos</p>
                <p className="seeall-para cursor-pointer" onClick={NavigateToallphotos}>Voir tout</p>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="grid grid-cols-2 gap-2">
                    {photos.map((photo, index) => (
                        <img
                            key={index}
                            src={`https://btobjob.urbancodez.com/${photo.photo_url}`}
                            width={70}
                            className="w-full object-contain"
                            alt={`User Photo ${index + 1}`}
                        />
                    ))}
                </div>
            )}
            
        </div>
           )}
            {/* 4th */}
            <div className="flex flex-col gap-2 justify-center items-start bg-white px-2 py-2 rounded-xl mt-3 w-full sm:w-[150px]">
              <div onClick={NavigateTochat} className="flex items-center justify-center gap-2 text-md cursor-pointer">
                <FaEnvelope className="" />
                <p className="div2-para">Envoyer le message</p>
              </div>
              {subscriptionExists && (
              <div className="flex items-center justify-center gap-2 text-md">
                <MdCurrencyFranc className="" size={20} />
                <p className="div2-para">   Les taux: {rate.minPrice} - {rate.maxPrice}</p>
              </div>

)}
   {subscriptionExists && (
              <div className={`${user?"flex":"hidden"} cursor-pointer`} onClick={toggleRate}>
                <div className="flex items-center justify-center gap-2 text-md">
                  <FaEdit className="" />
                  <p className="div2-para">Modifier le tarif</p>
                </div>
              </div>
                )}


              <div className="flex items-center "></div>
              <div className="flex items-center"></div>
            </div>
          </div>
          {/* right 👇🏻 */}
         <div className="flex flex-col gap-2 w-full sm:ml-4">
         {subscriptionExists && ( 
      <div className="flex items-center justify-center w-full bg-white py-2 rounded-xl">
        <input
          type="text"
          placeholder="Quoi de neuf ?" onClick={openModal}
          className="border-2 text-[11px] sm:text-xs px-1 py-1 rounded-md mr-1 md:w-2/3"
        />
        <button
          className="text-xs px-1 py-1 bg-[#65A406] rounded-md text-white"
          onClick={openModal}
        >
          Envoie
        </button>
        
      </div>
    )}
      <PostModal isOpen={isModalOpen} onClose={closeModal} />
      {subscriptionExists && (
            <div className="w-full bg-white py-2 px-2 rounded-xl">
      {position && (
        <MapContainer center={position} zoom={13} style={{ height: "400px", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>
              Your Location
            </Popup>
          </Marker>
          <MapEvents />
        </MapContainer>
      )}
    </div>
     )}
          {/* Post */}
          <div className="flex flex-col gap-1">
    {posts.map((post) => (
      <div key={post.id} className="bg-white p-8 shadow-md rounded-lg w-full">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <img
              src={post.profile_picture}
              alt="User Avatar"
              className="w-8 h-8 rounded-full"
            />
            <div>
              <p className="text-gray-800 font-semibold">{post.name}</p>
              <p className="text-gray-500 text-sm">{post.created_at}</p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-gray-800">{post.text}</p>
        </div>
        <div className={`mb-4 ${post.images.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
          {post.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Post Image ${index + 1}`}
              className="w-full h-48 object-cover rounded-md mb-2"
            />
          ))}
        </div>
        <div className="flex items-center justify-between text-gray-500">
          <div className="flex items-center space-x-2">
          <button
                className={`flex justify-center items-center gap-2 px-2 hover:bg-gray-50 rounded-full p-1 ${likesStatus[post.id] ? 'text-red-600' : 'text-gray-500'}`}
                onClick={() => handleLike(post.id)}
              >
                <svg
                  className={`w-5 h-5 ${likesStatus[post.id] ? 'fill-current text-red-600' : 'fill-gray-400'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 21.35l-1.45-1.32C6.11 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-4.11 6.86-8.55 11.54L12 21.35z" />
                </svg>
                {likesStatus[post.id] ? 'Liked' : 'Like'}
              </button>
            </div>
          </div>
        </div>
      ))}



            </div>
          </div>
        </div>
      </div>
      
      <ProfileShare boolean={openShare} />
      <Rate2 boolean={openRate}/>
    </>
  );
};

export default MainProfile;
