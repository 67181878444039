import React, { useState, useRef, useEffect } from 'react';
import UserProfile from "../../assests/profileuser.png";
import PostingPicture from '../../assests/postingpicture.png';
import { TiWorld } from "react-icons/ti";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import btnArrow from '../../assests/inputbtnarrow.png';
import Recommend from './Recommend';
import PublicRequest from './PublicRequest';
import AddressCard from './AddressCard';
import PublicRequestCategories from './PublicRequestCategories';
import { useNavigate } from "react-router-dom";
import PublicRequestCompleted from './PublicRequestCompleted';
import api from '../../services/api'; // Import API
import axios from 'axios';

const CommentsModal = ({ postId, isOpen, onClose, comments, fetchComments, handleAddComment, newComment, setNewComment }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative">
                <h2 className="text-xl font-bold mb-4">Comments</h2>
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-black"
                    onClick={onClose}
                >
                    ✕
                </button>
                <div className="space-y-2 mb-4 max-h-64 overflow-y-auto">
                    {Array.isArray(comments[postId]) && comments[postId].map(comment => (
                        <div key={comment.id} className="bg-gray-100 p-2 rounded-md">
                            <p className="text-sm font-semibold">{comment.user_name}</p>
                            <p className="text-sm text-gray-700">{comment.comment_text}</p>
                            <p className="text-xs text-gray-500">{comment.created_at}</p>
                        </div>
                    ))}
                </div>
                <input
                    type="text"
                    placeholder="Add a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="p-2 border rounded w-full mb-2"
                />
                <button
                    onClick={() => handleAddComment(postId)}
                    className="bg-blue-500 text-white px-4 py-1 rounded w-full"
                >
                    Add Comment
                </button>
            </div>
        </div>
    );
};

const Request = () => {
    const [posts, setPosts] = useState([]); // State for posts
    const [likesStatus, setLikesStatus] = useState({}); // State for like statuses
    const [openForm1, setOpenForm1] = useState(false); // State to control form visibility
    const [openForm2, setOpenForm2] = useState(false); // State to control form visibility
    const [isLightboxOpen, setIsLightboxOpen] = useState(false); // State for Lightbox
    const [lightboxImage, setLightboxImage] = useState(''); // Current image in Lightbox
    const [comments, setComments] = useState({}); // State to manage comments for each post
    const [newComment, setNewComment] = useState(''); // State to manage new comment input
    const [selectedPostId, setSelectedPostId] = useState(null); // State to manage selected post for modal
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const fileInputRef = useRef(null); // Reference to the file input

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Handle button click to open file input
    };

    const handleFileChange = (event) => {
        const files = event.target.files; // Handle file input change
        if (files.length > 0) {
            console.log('Selected file:', files[0]);
        }
    };

    const checkLikeStatus = async (postId) => {
        try {
            const response = await axios.post('https://btobjob.urbancodez.com/check-like.php', { postId }, { withCredentials: true });
            return response.data.liked;
        } catch (error) {
            console.error('Error checking like status:', error);
            return false;
        }
    };

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const postsData = await api.fetchAllPosts();
                setPosts(postsData); // Update posts state

                const likesPromises = postsData.map(post => checkLikeStatus(post.id));
                const likesResults = await Promise.all(likesPromises);

                const likesStatus = {};
                postsData.forEach((post, index) => {
                    likesStatus[post.id] = likesResults[index];
                });
                setLikesStatus(likesStatus);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    const handleLike = async (postId) => {
        try {
            const response = await axios.post('https://btobjob.urbancodez.com/like-post.php', { postId }, { withCredentials: true });
            setLikesStatus(prevStatus => ({
                ...prevStatus,
                [postId]: response.data.liked
            }));
        } catch (error) {
            console.error('Error liking the post:', error);
        }
    };

    const handleAddComment = async (postId) => {
        if (!newComment.trim()) return;
        try {
            const response = await axios.post('https://btobjob.urbancodez.com/add-post-comment.php', {
                postId,
                commentText: newComment
            }, { withCredentials: true });
    
            if (response.data.success) {
                setNewComment('');
                fetchComments(postId);
            } else {
                console.error('Error adding comment:', response.data.error);
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const fetchComments = async (postId) => {
        try {
            const response = await axios.post('https://btobjob.urbancodez.com/fetch-post-comments.php', {
                postId
            }, { withCredentials: true });
    
            if (response.status === 200) {
                setComments(prevComments => ({
                    ...prevComments,
                    [postId]: Array.isArray(response.data) ? response.data : []
                }));
            } else {
                console.error('Error fetching comments:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const openLightbox = (imageUrl) => {
        setLightboxImage(imageUrl);
        setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        setLightboxImage('');
    };

    const openCommentsModal = (postId) => {
        setSelectedPostId(postId);
        fetchComments(postId);
    };

    const closeCommentsModal = () => {
        setSelectedPostId(null);
    };

    // Filter posts based on the search term
    const filteredPosts = posts.filter(post =>
        post.text.toLowerCase().includes(searchTerm.toLowerCase()) || 
        post.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='max-w-6xl mx-auto md:mt-10 px-2 py-2'>
            <div className='flex gap-3 md:flex-row flex-col-reverse'>
                <div className='flex flex-col w-full md:w-2/3'>
                    <div className='rounded-xl bg-white'>
                        <div className='sm:p-6 p-3 border-b-2'>
                            {/* Search Bar */}
                            <input
                                type="text"
                                placeholder="Search posts..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="flex flex-col gap-1">
                            {filteredPosts.map((post) => (
                                <div key={post.id} className="bg-white p-8 shadow-md rounded-lg w-full">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center space-x-2">
                                            <img
                                                src={post.profile_picture}
                                                alt="User Avatar"
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <div>
                                                <p className="text-gray-800 font-semibold">{post.name}</p>
                                                <p className="text-gray-500 text-sm">{post.created_at}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <p className="text-gray-800">{post.text}</p>
                                    </div>
                                    <div className={`mb-4 ${post.images.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
                                        {post.images.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Post Image ${index + 1}`}
                                                className="w-full h-48 object-cover rounded-md mb-2 cursor-pointer"
                                                onClick={() => openLightbox(image)}
                                            />
                                        ))}
                                    </div>
                                    <div className="flex items-center justify-between text-gray-500">
                                        <button
                                            onClick={() => openCommentsModal(post.id)}
                                            className="text-blue-500"
                                        >
                                            View Comments
                                        </button>
                                        <button
                                            className={`flex justify-center items-center gap-2 px-2 hover:bg-gray-50 rounded-full p-1 ${likesStatus[post.id] ? 'text-red-600' : 'text-gray-500'}`}
                                            onClick={() => handleLike(post.id)}
                                        >
                                            <svg
                                                className={`w-5 h-5 ${likesStatus[post.id] ? 'fill-current text-red-600' : 'fill-gray-400'}`}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 21.35l-1.45-1.32C6.11 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-4.11 6.86-8.55 11.54L12 21.35z" />
                                            </svg>
                                            {likesStatus[post.id] ? 'Liked' : 'Like'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='md:w-1/3 w-full flex flex-col gap-2'>
                    <div onClick={() => setOpenForm2(!openForm2)} className="w-full sm:p-8 p-3 shadow-md rounded-xl bg-white">
                        <div className="flex items-center justify-between mb-1 sm:mb-4">
                            <div className='flex items-center mr-5'>
                                <TiWorld className="w-6 h-6" />
                                <h2 className="text-2xl font-bold sm:font-bold">Demande</h2>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => setOpenForm2(!openForm2)} className="w-full rounded-xl bg-gradient-to-r text-white from-lime-400 to-lime-600 px-2 py-2 flex items-center justify-center">
                                Postez votre demande
                                <img className="ml-2" src={btnArrow} width={17} alt="Arrow" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Include additional components at the bottom */}
            <Recommend toggleboolean={openForm1} />
            <PublicRequest toggleboolean={openForm2} updateFormState={setOpenForm2} />

            {/* Lightbox for displaying images */}
            {isLightboxOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative">
                        <img src={lightboxImage} alt="Lightbox" className="max-w-full max-h-screen rounded-lg" />
                        <button
                            className="absolute top-2 right-2 text-white bg-gray-800 rounded-full p-1"
                            onClick={closeLightbox}
                        >
                            ✕
                        </button>
                    </div>
                </div>
            )}

            {/* Comments Modal for displaying comments */}
            <CommentsModal
                postId={selectedPostId}
                isOpen={selectedPostId !== null}
                onClose={closeCommentsModal}
                comments={comments}
                fetchComments={fetchComments}
                handleAddComment={handleAddComment}
                newComment={newComment}
                setNewComment={setNewComment}
            />
        </div>
    );
};

export default Request;
