import React, { useState, useEffect } from 'react';
import { CiChat1, CiTwitter } from "react-icons/ci";
import { PiTelegramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";
import { MdAlternateEmail } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { GoCopy } from "react-icons/go";
import axios from 'axios';
import api from '../../services/api'; // Ensure you have this import or your actual API import


const ProfileShare = ({ boolean }) => {
    const [localToggle, setLocalToggle] = useState(boolean);

    const [profileData, setProfileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        if (localToggle) {
            console.log('Form has been toggled. Fetching recommendations...');
            // Fetch recommendations or perform any other necessary actions
        }
    }, [localToggle]);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await api.fetchProfile();
                setProfileData(response);
            } catch (error) {
                console.error("Error fetching profile data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    // Function to copy the URL to the clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`);
        alert('Link copied to clipboard!');
    };

    // Function to share via email
    const shareViaEmail = () => {
        window.open(`mailto:?subject=Check%20this%20out&body=${(`https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`)}`);
    };

    // Function to share via WhatsApp
    const shareViaWhatsApp = () => {
        window.open(`https://wa.me/?text=${(`https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`)}`);
    };

    // Function to share via Telegram
    const shareViaTelegram = () => {
        window.open(`https://t.me/share/url?url=${(`https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`)}`);
    };

    // Function to share via Twitter
    const shareViaTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?url=${(`https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`)}`);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!profileData) {
        return <div>Profile data not found.</div>;
    }


    return (
        <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-[9999] bg-black/50`}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-[500px] relative">
                <div className="absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setLocalToggle(!localToggle)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <h2 className="text-lg font-semibold mb-4">Partager avec</h2>
                <div className="flex justify-around mb-6">
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaEmail}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <MdAlternateEmail className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">E-mail</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaWhatsApp}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <PiWhatsappLogoLight className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">WhatsApp</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaTelegram}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <PiTelegramLogoLight className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Telegram</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer" onClick={shareViaTwitter}>
                        <div className="bg-gray-200 rounded-full p-4">
                            <CiTwitter className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Twitter</span>
                    </div>
                    <div className="flex flex-col items-center cursor-pointer">
                        <div className="bg-gray-200 rounded-full p-4" onClick={copyToClipboard}>
                            <GoCopy className="h-6 w-6" />
                        </div>
                        <span className="mt-2 text-sm">Copy Link</span>
                    </div>
                </div>
                <div className="text-center mb-4 text-gray-500">Or share with link</div>
                <div className="flex items-center justify-between bg-gray-100 p-2 rounded-lg">
                    <input 
                        type="text" 
                        value={`https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`} 
                        className="bg-gray-100 text-gray-700 text-sm w-full focus:outline-none" 
                        readOnly 
                    />
                    <button className="bg-red-100 text-red-500 p-2 rounded-full ml-2" onClick={copyToClipboard}>
                        <GoCopy className="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileShare;
