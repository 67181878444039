import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegMap } from "react-icons/fa";
import { CiCalendar, CiStopwatch, CiWallet } from "react-icons/ci";
import { IoLayersOutline, IoBagOutline } from "react-icons/io5";
import ProfileShare from '../ProfileView/ProfileShare';
import UploadingCv from './UplaodingCv';
import api from '../../services/api';


const JobDescription = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [openCV, setOpenCV] = useState(false);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData = await api.fetchJobById(jobId);
        setJob(jobData);
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };
    if (jobId) {
      fetchJob();
    }
  }, [jobId]);

  const getUpdatedState = (state) => {
    setOpenCV(state);
  };

  const copyUrlToClipboard = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert("Link has been copied!");
    }).catch((error) => {
      console.error("Failed to copy URL: ", error);
    });
  };

  if (!job) {
    return <div>Loading...</div>;
  }

  const jobDetails = [
    {
      icon: <CiCalendar size={24} color='#65A406' />,
      label: "Emploi affiché",
      value: job.job_posted_date || "N/A"
    },
    {
      icon: <CiStopwatch size={24} color='#65A406' />,
      label: "Le travail expire dans",
      value: job.job_expires_on || "N/A"
    },
    {
      icon: <IoLayersOutline size={24} color='#65A406' />,
      label: "Le niveau d'emploi",
      value: job.entry_level_text || "N/A"
    },
    {
      icon: <CiWallet size={24} color='#65A406' />,
      label: "Expérience",
      value: job.salary || "N/A"
    },
    {
      icon: <IoBagOutline size={24} color='#65A406' />,
      label: "Éducation",
      value: job.experience_education || "N/A"
    }
  ];

  const decodeHtmlEntities = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 flex justify-between">
          <div className="flex items-center mb-4">
            <img className="md:w-16 md:h-16 w-10 h-10 object-cover rounded-full" src={job.ProfilePicture || "https://via.placeholder.com/64"} alt="company logo" />
            <div className="ml-4">
              <h2 className="md:text-2xl text-lg font-bold text-gray-800">
                {decodeHtmlEntities(job.job_title) || "N/A"}</h2>
              <p className="text-gray-600">at {job.work_place || "N/A"} <span className="bg-[#65A406] text-white text-xs py-1 px-2 rounded-sm">{job.job_type ? job.job_type.toUpperCase() : "N/A"}</span></p>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button className="bg-[#65A406] text-white md:p-2 p-1 rounded-lg text-xs md:text-sm" onClick={() => setOpenCV(!openCV)}>Postuler</button>
            <button className="bg-[#65A406] text-white md:p-2 p-1 rounded-lg text-xs md:text-sm" onClick={copyUrlToClipboard}>partager</button>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-6">
          <div className="lg:col-span-2">
            <h3 className="text-xl font-semibold mb-4">Description de l'emploi</h3>
            <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(job.description) }} />
          </div>
          <div className="bg-gray-100 p-4 rounded-lg flex flex-col gap-y-4">
            <div className='flex items-center justify-between border p-1'>
              <div className='flex flex-col items-center md:border-r-2 p-4'>
                <p>Salarie <span>EURO</span></p>
                <p className="text-lg text-[#65A406]">{job.salary || "N/A"}</p>
                <p className='text-sm text-gray-400'>Salarie Annuel</p>
              </div>
              <div className='flex flex-col items-start p-2'>
                <FaRegMap size={24} color='#65A406' />
                <p className="font-semibold text-lg">lieu de travail</p>
                <p className='text-sm text-gray-400'>{job.work_place || "N/A"}</p>
              </div>
            </div>
            <div className='flex items-start flex-col justify-between border p-1'>
              <p className='text-lg'>Aperçu du poste</p>
              <div className='flex flex-row flex-wrap gap-4 w-full'>
                {jobDetails.map((detail, index) => (
                  <div key={index} className='flex flex-col items-start md:p-2 p-4'>
                    {detail.icon}
                    <p className='text-sm text-gray-400'>{detail.value}</p>
                    <p className="font-semibold text-base">{detail.label}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <UploadingCv boolean={openCV} returnState={getUpdatedState} jobId={jobId} />

    </div>
  );
};

export default JobDescription;
