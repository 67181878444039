
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'; // Import axios

const ViewRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();
  const limit = 10; // Define a consistent number of requests per page

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        // Updated to use axios.get with direct API call
        const response = await axios.get(`https://btobjob.urbancodez.com/get-requests-by-id.php?page=${page}&limit=${limit}`, { withCredentials: true });
        console.log('API response:', response.data); // Debugging log
        const { requests, total } = response.data;

        setRequests(requests);
        setTotal(total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching requests:", error);
        setLoading(false);
      }
    };

    fetchRequests();
  }, [page]);

  const handleRequestClick = (professionalId) => {
    navigate(`/profile/specificUser?professional_id=${professionalId}`);
  };

  const handleNextPage = () => {
    if (page * limit < total) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePrivateRequestClick = () => {
    navigate('/profile/PrivateRequest');
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">mes demandes</h2>

      <div className="flex justify-center mt-6">
        <button 
          onClick={handlePrivateRequestClick} 
          className="bg-green-500 text-white px-4 mb-2 py-1 rounded hover:bg-green-600 transition duration-300"
        >
          Voir la demande privée
        </button>
      </div>

      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <div className="space-y-6">
          {requests.length === 0 ? (
            <p className="text-center">No requests found.</p>
          ) : (
            requests.map((request) => (
              <div key={request.id} className="p-4 border border-gray-300 rounded-lg">
                <h3 className="text-lg font-bold mb-2">{request.request}</h3>

                <div className="flex justify-between mb-4">
                  <div className="flex flex-col">
                    <span className="font-semibold">Category:</span>
                    <span>{request.category}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Price:</span>
                    <span>{request.price} Francs</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Address:</span>
                    <span>{request.address}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Time:</span>
                    <span>{request.time}</span>
                  </div>
                </div>

                <div className="flex gap-4">
                  {request.file1 && (
                    <img src={`https://btobjob.urbancodez.com/${request.file1}`} alt="File 1" className="w-20 h-20 object-cover rounded-lg" />
                  )}
                  {request.file2 && (
                    <img src={`https://btobjob.urbancodez.com/${request.file2}`} alt="File 2" className="w-20 h-20 object-cover rounded-lg" />
                  )}
                  {request.file3 && (
                    <img src={`https://btobjob.urbancodez.com/${request.file3}`} alt="File 3" className="w-20 h-20 object-cover rounded-lg" />
                  )}
                </div>

                <button
                  onClick={() => handleRequestClick(request.user_id)}
                  className="bg-blue-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 transition duration-300"
                >
                  View Profile
                </button>
              </div>
            ))
          )}
        </div>
      )}

      <div className="flex justify-between mt-6">
        <button 
          onClick={handlePrevPage} 
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          disabled={page === 1}
        >
          Previous
        </button>
        <button 
          onClick={handleNextPage} 
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          disabled={page * limit >= total}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ViewRequests;