import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobilePhone from '../assests/mobilephone.png';

const Homephone = () => {
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState('Votre e téléphone');

  const handleRadioChange = (e) => {
    if (e.target.value === 'Téléphone') {
      setPlaceholder('Votre e téléphone');
    } else if (e.target.value === 'email') {
      setPlaceholder('Votre e email');
    }
  };

  const NavigateToContact = () => {
    navigate('/contact');
  };

  return (
    <div className="mb-10 sm:mb-20 mt-10 sm:mt-20 md:p-10 bg-[#FFF]">
      <div className="grid grid-cols-1 sm:grid-cols-2 p-6">
        <div className="flex flex-col justify-center ">
          <div className='flex items-center font-bold md:text-5xl text-xl relative mb-8'>
            <p className=''>Aplication </p> 
            <p className='ml-2'>bientôt</p>
            <p className='ml-2 text-green-700'>disponible</p>
            {/* <img className="w-full object-contain h-52 hidden md:block absolute -right-48 -top-12" src={ObtenirLogo} alt="Logo" /> */}
          </div>

          <p className="text-base font-normal mb-4">
         La plateforme offre une gamme d'outils et d'opportunités pour développer vos activités et établir des partenariats solides dans divers secteurs, révélant ainsi de nouvelles perspectives pour chacun. Imaginez : une entreprise de rénovation confiant le développement à un commercial indépendant pour un salon de l'habitation, ou une secrétaire qui sous-traite une entreprise de construction ou à un mécanicien. Un plâtrier, un peintre, un représentant des ventes, une entreprise de rénovation et bien d'autres peuvent collaborer à divers projets.
          </p>
          <div className="flex items-center ">
            <input
              type="radio"
              id="telephone"
              name="contact"
              value="telephone"
              defaultChecked
              onChange={handleRadioChange}
              className="mr-2"
            />
            <label htmlFor="telephone" className="mr-4">Téléphone</label>
            <input
              type="radio"
              id="email"
              name="contact"
              value="email"
              onChange={handleRadioChange}
              className="mr-2"
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className='flex flex-col md:flex-row items-center mt-9'>
            <input
              type="text"
              placeholder={placeholder}
              className="w-full sm:w-1/2 p-2  border border-gray-300 rounded"
            />
            <button onClick={NavigateToContact} className=" text-white py-2 px-4 rounded bg-gradient-to-b from-lime-400 to-lime-700 w-full md:w-[160px] mt-1 md:mt-0">
              de l'application
            </button>
          </div>

        </div>
        
        <div className="flex justify-center items-center ">
          <img src={MobilePhone} alt="Mobile Phones" className="w-3/4 lg:h-[150%] h-[120%] sm:w-1/2" />
        </div>
      </div>
    </div>
  );
};

export default Homephone;
