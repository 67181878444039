import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaHeart, FaRegHeart } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'; // Adjust the path as necessary

const BuyAWebsite = () => {
    const navigate = useNavigate();
    const [websites, setWebsites] = useState([]);
    
    const NavigateToBack = () => {
        navigate(-1); // Navigates back one step in the history stack
    };

    const NavigateToSpecificWebsite = (id) => {
        navigate(`/profile/specificwebsite/${id}`); // Navigates to a specific website page with the ID
    };

    const toggleLike = (id) => {
        setWebsites(websites.map(website =>
            website.id === id ? { ...website, liked: !website.liked } : website
        ));
    };

    useEffect(() => {
        const fetchWebsites = async () => {
            try {
                const data = await api.fetchWebsites();
                setWebsites(data.map(website => ({ ...website, liked: false })));
            } catch (error) {
                console.error('Error fetching websites:', error);
            }
        };

        fetchWebsites();
    }, []);

    return (
        <div className="md:mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-12 m-2 mx-2">
            <div className='flex flex-col'>
                <div className='p-6 border-b-2 flex flex-col'>
                    <div className='flex items-center'>
                        <FaArrowLeft onClick={NavigateToBack} className='h-6 w-6 pr-2' />
                        <p className="text-xl font-semibold">Marchés pour acheter et vendre des sites Web</p>
                    </div>
                    <p className='text-xs'>Hey Professionnel, son site est pour vous</p>
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                    {websites.map(website => (
                        <div key={website.id} className="p-4 relative">
                            <div className="bg-white border rounded-lg w-full">
                                <div onClick={() => NavigateToSpecificWebsite(website.id)} className='w-full h-[160px]'>
                                    <img src={`https://btobjob.urbancodez.com/admin/backend/${website.images[0]}`} alt={website.title} className='w-48 rounded-tl-lg rounded-tr-lg object-cover' />
                                </div>

                                <div className="flex items-center justify-between mx-4 mt-3 mb-2">
                                   
                                </div>
                            </div>
                            {website.label && (
                                <div className='bg-[#65A406] w-28 rounded-lg flex justify-center absolute top-7 left-3'>
                                    <p className='text-white font-bold'>{website.label}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BuyAWebsite;
