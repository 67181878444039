import axios from 'axios';
const instance = axios.create({
  baseURL: 'https://btobjob.urbancodez.com/', // Replace with your actual backend URL

  withCredentials: true, // Adjust as needed
});
const API_URL = 'https://btobjob.urbancodez.com/';

const api = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/login-customer.php`, { email, password }, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Login error', error);
      throw error;
    }
  },
  LoginPro: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/login-professional.php`, { email, password }, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Login error', error);
      throw error;
    }
  },
  getSession: async () => {
    try {
      const response = await axios.get(`${API_URL}/get-session.php`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Get session error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  getUser: async () => {
    try {
      const response = await axios.get(`${API_URL}/get-user.php`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Get session error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  logout: async () => {
    try {
      const response = await axios.post(`${API_URL}/logout.php`, {}, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Logout error', error);
      throw error;
    }
  },
  insertContactData: async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/contact.php`, formData);
      return response.data;
    } catch (error) {
      console.error('Error inserting contact data:', error);
      throw error;
    }
  },
  signup: async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/create-customer.php`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error during signup:', error);
      throw error;
    }
  },
  addOffer: async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/add-offer.php`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding offer:", error);
      throw error;
    }
  },

  getRequests: async (page = 1, limit = 10) => {
    try {
      const response = await axios.get(`${API_URL}/get-requests.php`, { 
        params: { page, limit },
        withCredentials: true 
      });
      console.log('Fetched requests:', response.data); // Debugging log
      return response.data;
    } catch (error) {
      console.error('Error fetching requests:', error);
      throw error;
    }
  },
  getPrivateRequests: async (page = 1, limit = 10) => {
    try {
      const response = await axios.get(`${API_URL}/get-private-requests.php`, { 
        params: { page, limit },
        withCredentials: true 
      });
      console.log('Fetched private requests:', response.data); // Debugging log
      return response.data;
    } catch (error) {
      console.error('Error fetching private requests:', error);
      throw error;
    }
  },
  
  fetchJobs: async () => {
    try {
      const response = await axios.get(`${API_URL}/fetch-jobs.php`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error fetching jobs:', error);
      throw error;
    }
  },
  fetchJobById: async (jobId) => {
    try {
      const response = await axios.get(`${API_URL}/fetch-job.php?id=${jobId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching job details:', error);
      throw error;
    }
  },
  uploadFiles: async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/upload-cv.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error uploading files: ${error.message}`);
    }
  },

  
    fetchOffers: async () => {
      try {
        const response = await axios.get(`${API_URL}/get-offers.php`, { withCredentials: true });
        return response.data;
      } catch (error) {
        console.error('Error fetching offers:', error);
        throw error;
      }
    },
    updateProfile: async (formData) => {
      try {
        const response = await axios.post(`${API_URL}/update-profile.php`, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        return response.data;
      } catch (error) {
        console.error('Error updating profile:', error);
        throw error;
      }
    },
    fetchWebsites: async () => {
      try {
          const response = await axios.get(`${API_URL}/fetch-websites.php`, { withCredentials: true });
          return response.data;
      } catch (error) {
          console.error('Error fetching websites:', error);
          throw error;
      }
  },
  fetchRentals: async (page = 1, limit = 10, type = '') => {
    try {
        const response = await axios.get(`${API_URL}/get-rentals.php`, {
            params: { page, limit, type },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching rentals:', error);
        throw error;
    }
  },
  fetchTypes: async () => {
    try {
        const response = await axios.get(`${API_URL}/get-rentals-types.php`, { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error('Error fetching rentals:', error);
        throw error;
    }
  },
addToCart: async (items) => {
  try {
      const response = await axios.post(`${API_URL}/add-to-cart.php`, items, { withCredentials: true });
      return response.data;
  } catch (error) {
      console.error('Error adding to cart:', error);
      throw error;
  }
},
  createForumTopic: async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/create-topic.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error creating forum topic:', error);
      throw error;
    }
  },
 
    fetchForumTopics: async () => {
      try {
        const response = await axios.get(`${API_URL}/fetch-forum-topics-by-id.php`, { withCredentials: true });
        return response.data; // Assuming your backend returns an array of topics
      } catch (error) {
        console.error('Error fetching forum topics:', error);
        throw error;
      }
    },

  fetchForumTopicById: async (topicId) => {
    try {
      const response = await axios.get(`${API_URL}/fetch-forum-topic-by-topic-id.php?id=${topicId}`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error fetching forum topic by ID:', error);
      throw error;
    }
  },
 
  


  submitComment: async (commentData) => {
    try {
      const response = await axios.post(`${API_URL}/submit-comment.php`, commentData, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting comment:', error);
      throw error;
    }
  },
  fetchComments: async (topicId) => {
    try {
      const response = await axios.get(`${API_URL}/fetch-comments.php?topic_id=${topicId}`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  },
  sendPrivateRequest: async (request) => {
    try {
        const response = await axios.post(`${API_URL}/send-private-request.php`, request, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending private request:', error);
        throw error;
    }
},
sendMessage: async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/send-message.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Send message error:', error.response ? error.response.data : error.message);
    throw error;
  }
},
sendMessagee: async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/send_messagee.php`, formData, {
      headers: {
      'Content-Type': 'application/json'
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Send message error:', error.response ? error.response.data : error.message);
    throw error;
  }
},


fetchProfileById: async (professionalId) => {
  try {
    const response = await axios.get(`${API_URL}/fetch-professional-by-id.php?id=${professionalId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error("Error fetching profile by ID:", error);
    throw error;
  }
},
fetchProfile: async () => {
  try {
    const response = await axios.get(`${API_URL}/fetch-professional.php`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error("Error fetching profile by ID:", error);
    throw error;
  }
},
getSubscription: async () => {
  try {
      const response = await instance.get('/get-subscription.php', { withCredentials: true });
      return response.data;
  } catch (error) {
      console.error('Get subscription error', error);
      throw error;
  }
},
updateLocation: async (professionalId, latitude, longitude) => {
  try {
    const response = await axios.post(`${API_URL}/update-location.php`, {
      id: professionalId,
      latitude: latitude,
      longitude: longitude
    }, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error("Error updating location:", error);
    throw error;
  }
},
updateImages: async (profileImage, coverImage) => {
  try {
    const formData = new FormData();
    if (profileImage) formData.append('profileImage', profileImage);
    if (coverImage) formData.append('coverImage', coverImage);
    const response = await instance.post('/update-images.php', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading images:', error.response ? error.response.data : error.message);
    throw error;
  }
},

fetchProfileImage: async () => {
  try {
    const response = await axios.get(`${API_URL}/fetch-profile-image.php`, { withCredentials: true });
    return response.data; // Assumes { imageUrl: 'url_to_profile_image' }
  } catch (error) {
    console.error('Error fetching profile image:', error);
    throw error;
  }
},

fetchCoverImage: async () => {
  try {
    const response = await axios.get(`${API_URL}/fetch-cover-image.php`, { withCredentials: true });
    return response.data; // Assumes { imageUrl: 'url_to_cover_image' }
  } catch (error) {
    console.error('Error fetching cover image:', error);
    throw error;
  }
},
fetchProfileImagebyid: async (professionalId) => {
  try {
    const response = await axios.get(`${API_URL}/fetch-profile-image-by-id.php?id=${professionalId}`, { withCredentials: true });
    return response.data; // Assumes { imageUrl: 'url_to_profile_image' }
  } catch (error) {
    console.error('Error fetching profile image:', error);
    throw error;
  }
},

fetchCoverImagebyid: async (professionalId) => {
  try {
    const response = await axios.get(`${API_URL}/fetch-cover-image-by-id.php?id=${professionalId}`, { withCredentials: true });
    return response.data; // Assumes { imageUrl: 'url_to_cover_image' }
  } catch (error) {
    console.error('Error fetching cover image:', error);
    throw error;
  }
},

fetchPosts: async () => {
    try {
      const response = await axios.get(`${API_URL}/fetch-posts.php`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  },
  fetchAllPosts: async (query = '', page = 1, postsPerPage = 10) => {
    try {
      const response = await axios.get(`${API_URL}/fetch-all-posts.php`, {
        params: { query, page, postsPerPage },
        withCredentials: true
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  },
 checkLikeStatus: async (postId) => {
    try {
      const response = await axios.post('https://btobjob.urbancodez.com/check-like.php', { postId }, { withCredentials: true });
      return response.data.liked;
    } catch (error) {
      console.error('Error checking like status:', error);
      return false;
    }
  },
  
  fetchSpecificPosts: async (professionalId) => {
    try {
      const response = await axios.get(`${API_URL}/fetch-specific-posts.php`, { 
        params: { professional_id: professionalId },
        withCredentials: true 
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  },
  fetchContacts: async () => {
    try {
      const response = await axios.get(`${API_URL}/getContacts.php`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching contacts:', error);
      throw error;
    }
  },

  fetchContactById: async (contactId) => {
    try {
      const response = await axios.post(`${API_URL}/getContact.php`, 
        { contact_id: contactId },
        { withCredentials: true }
      );
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message || 'Failed to fetch contact');
      }
    } catch (error) {
      console.error('Error fetching contact:', error);
      throw error;
    }
  },

  fetchConversation: async (contactId) => {
    try {
      const response = await axios.post(`${API_URL}/getConversation.php`, 
        {       receiver_id: contactId },
        {
          withCredentials: true // Ensure cookies/session are sent
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching conversation:', error);
        return { success: false, message: 'Error fetching conversation' };
      }
  },
  fetchAllAds: async (query = '', page = 1, adsPerPage = 10) => {
    try {
      const response = await axios.get(`${API_URL}/fetch-all-ads.php`, {
        params: { query, page, adsPerPage },
        withCredentials: true
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching ads:', error);
      throw error;
    }
  },
}

export default api;
