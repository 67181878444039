import React, { useState } from 'react';
import axios from 'axios';

const API_URL = 'https://btobjob.urbancodez.com/'; // Adjust based on your actual API URL

const PostModal = ({ isOpen, onClose }) => {
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles.slice(0, 5)); // Limit to 5 files
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('text', text);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    try {
      const response = await axios.post(`${API_URL}/create-post.php`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Post created:', response.data);
      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
      <div className="bg-white p-4 rounded-xl w-full max-w-lg">
        <h2 className="text-lg mb-2">Post something new</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            className="w-full border-2 p-2 rounded-md mb-2"
            rows="4"
            placeholder="What's new?"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full mb-2"
          />
          <div className="flex flex-wrap gap-2 mb-2">
            {files.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt="Selected file"
                className="w-20 h-20 object-cover rounded-md"
              />
            ))}
          </div>
          <button type="submit" className="bg-[#65A406] text-white px-4 py-2 rounded-md">
            Post
          </button>
          <button type="button" onClick={onClose} className="bg-gray-300 text-black px-4 py-2 rounded-md ml-2">
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default PostModal;
