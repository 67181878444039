import React, { useState, useEffect } from "react";
import userProfile from "../assests/people2.png";
import { RiRadioButtonLine } from "react-icons/ri";
import { MdMissedVideoCall } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SlOptions } from "react-icons/sl";
import { IoCall } from "react-icons/io5";
import api from '../services/api';

const ChatApp = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await api.fetchContacts();
        if (data.success) {
          setContacts(data.contacts);
        } else {
          setError(data.message || 'Failed to fetch contacts');
        }
      } catch (error) {
        setError('Failed to fetch contacts');
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleContactClick = (contactId) => {
    navigate(`/profile/conversation?id=${contactId}`);
  };

  return (
    <div className="flex overflow-hidden max-w-6xl md:mt-10 md:mx-auto m-2 h-[700px] rounded-xl">
      <div className="w-full bg-white border-r border-gray-300 h-[700px]">
        <div className="relative p-2">
          <input
            className="appearance-none border-2 pl-10 border-gray-300 hover:border-gray-400 transition-colors w-full py-2 px-3 text-gray-800 leading-tight bg-gray-100 focus:outline-none focus:ring-lime-600 focus:border-lime-600 focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="absolute right-0 inset-y-0 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="-ml-1 mr-3 h-5 w-5 text-gray-400 hover:text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="absolute left-0 inset-y-0 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-3 text-gray-400 hover:text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>

        <div className="overflow-y-auto h-[700px] p-3">
          {loading ? (
            <p>Loading contacts...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : filteredContacts.length === 0 ? (
            <p>No contacts found</p>
          ) : (
            filteredContacts.map((contact) => (
              <div
                key={contact.id}
                onClick={() => handleContactClick(contact.id)}
                className="flex items-center mb-4 cursor-pointer hover:bg-gray-100 p-2 rounded-md"
              >
                <div className="w-12 h-12 bg-gray-300 rounded-full mr-3">
                  <img
                    src={contact.profile_picture || `https://placehold.co/200x/${Math.floor(
                      Math.random() * 16777215
                    ).toString(16)}/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato`}
                    alt="User Avatar"
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div className="flex-1">
                  <h2 className="text-lg font-semibold">{contact.name}</h2>
                  <p className="text-gray-600">{contact.last_message || "No recent messages"}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
