import './login.css'
import { useState, useEffect, useRef } from 'react';
import { MdOutlineNotifications } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import LOGO2 from '../assests/b2bjob logo 1.png'
import { FaHome } from "react-icons/fa";
import { RiDiscountPercentFill } from "react-icons/ri";
import { MdAddComment } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";
import { MdForum } from "react-icons/md";
import UserProfile from '../assests/profileuser.png'
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { HiSearchCircle } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { FaInfoCircle } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoMdShare } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { BiSolidCategoryAlt } from "react-icons/bi";
import PublicOrPrivate from './PublicOrPrivate';
import ProfileShare from './ProfileView/ProfileShare';
import { BiSolidMessageSquare } from "react-icons/bi";
import Notifications from './Notifications';
import requests from './RequestPage/Request'
import api from '../services/api'
import { FaFlag } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { FaPoll } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa";
import { FaTrello } from "react-icons/fa";

function NavbarProfile() {
    const [isOpen, setIsOpen] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [nav, setNav] = useState(false);
    const [openDiv, setOpenDiv] = useState(false);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [openShare, setOpenShare] = useState(false);
    const [openNotif, setopenNotif] = useState(false);
    
    const dropdownRef = useRef(null); // Reference for dropdown

    const getDivState = (state) => {
        setOpenDiv(state);
    };

    const getNotifState = (state) => {
        setopenNotif(state);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const profileData = await api.fetchProfileImage();
                setProfileImagePreview(profileData.imageUrl);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);

    const handleLogout = async () => {
        try {
            await api.logout();
            setIsLoggedIn(false);
            setNav(false);
            window.location.reload();
        } catch (error) {
            console.error('Logout error', error);
        }
    };

    const navigate = useNavigate();

    const NavigateToOffers = () => navigate("/profile/offers");
    const NavigateToHome = () => navigate("/profile/request");
    const NavigateTosubscription = () => navigate("/profile/subscription");
    const NavigateTochat = () => navigate("/profile/chat");
    const NavigateToViewRequests = () => navigate("/profile/viewrequests");
    const NavigateToViewAllRequests = () => navigate("/profile/ViewAllRequests");
    const NavigateToforum = () => navigate("/profile/forum");
    const NavigateTowebsite = () => navigate("/profile/website");
    const NavigateToviewprofile = () => navigate("/profile/viewprofile");
    const NavigateToAllservices = () => navigate("/profile/services");
    const NavigateToAllacategories = () => navigate("/profile/services_rentals");
    const NavigateToAllads = () => navigate("/profile/ads");
    const NavigateToeditprofile = () => navigate("/profile/editprofile");
    const NavigateToChangePicture = () => navigate("/profile/updatepicturepage");
    const NavigateTochangepassword = () => navigate("/profile/changepassword");
    const NavigateToAddOffer = () => navigate("/profile/addoffer");
    const NavigateToCard = () => navigate("/profile/businesscard");
    const NavigateToBTBHome = () => navigate("/");

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const sessionData = await api.getSession();
                if (!sessionData.user || !sessionData.user.id) {
                    navigate('/professionaluser');
                }
            } catch {
                navigate('/professionaluser');
            }
        };
        fetchSession();
    }, [navigate]);

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const { subscription_exists } = await api.getSubscription();
                setSubscription(subscription_exists);
            } catch (error) {
                console.error('Error fetching subscription:', error);
                setSubscription(false);
            }
        };
        fetchSubscription();
    }, []);

 


  

    return (
        <>
         <nav className="flex flex-col md:flex-row w-full bg-slate-50 justify-between items-center px-2 py-2 z-[10000] relative">
                {/* Left */}
                <div className="flex items-center mb-2 md:mb-0">
                    <img onClick={NavigateToBTBHome} src={LOGO2} alt="Logo" className="w-full object-contain h-10 mr-2 mt-2 hidden md:block" />
                    {/* <div className="profile-search-input">
                        <FaSearch className="profile-search-icon" />
                        <input type="text" className="profile-search" placeholder="Search Jobs and Requests" />
                    </div> */}
                </div>
                {/* center */}
                <div className="flex gap-2 md:gap-3 mb-4 sm:mb-0">
                {subscription && (
                    <div onClick={NavigateToHome} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <FaHome  />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Accueil</p>
                    </div>
                  )}


{subscription ? (         
    <div onClick={NavigateToOffers} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
        <RiDiscountPercentFill />
        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Offres</p>
    </div>
   ) : null}

                    <div onClick={()=>(setOpenDiv(!openDiv))} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">

                        < MdAddComment  />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Demandes</p>
                    </div>
                    <div onClick={NavigateTosubscription} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <IoPersonAdd  />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Tarifs</p>
                    </div>
                    <div onClick={NavigateTochat} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <BiSolidMessageSquare  />
                        <p className="text-[7px]  sm:text-base font-normal text-black hover:text-[#65a406]">Messagerie</p>
                    </div>
                    {subscription ? (
                        <div onClick={NavigateToforum} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                            <MdForum  />
                            <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Forum</p>
                        </div>
                    ) : null}
                    <div onClick={NavigateTowebsite} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <CgWebsite  />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Websites</p>
                    </div>  
                    <div onClick={NavigateToAllads} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                    <FaTrello />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Ads</p>
                    </div>
                    {subscription ? (
                    <div onClick={NavigateToAllacategories} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <BiSolidCategoryAlt />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">location/vente</p>
                    </div>
                          ) : null}
                    <div onClick={NavigateToAllservices} className="flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <MdOutlineMiscellaneousServices  />
                        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Emploi,Formation ,stage</p>
                    </div>
                </div>
                {/* Right */}
                <div className="flex">
                    {/* <form>
                        <div className="profile-select-container">
                            <FaMapMarkerAlt className="profile-location-icon" />
                            <select className="navbar-select">
                                <option className='profile-option' value="option1">France</option>
                                <option className='profile-option' value="option2">Option 2</option>
                                <option className='profile-option' value="option3">Option 3</option>
                            </select>
                        </div>
                    </form> */}
                   <div className="flex justify-between border-2 rounded-lg p-1 w-16 mr-5">
                <MdOutlineNotifications onClick={() => setopenNotif(!openNotif)} className="text-[#65A406] hover:cursor-pointer" />
                <div className='relative' ref={dropdownRef}>
                    <RiContactsLine
                        className="text-[#65A406] hover:cursor-pointer"
                        onClick={handleToggle}
                    />
                           {isOpen && (
                        <div
                            className="absolute md:right-0 mt-5 w-[180px] md:w-[240px] bg-white rounded-md shadow-md z-10"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                        >
                                   <ul className="py-7">
                                {/* Close button and other options */}
                                <MdCancel onClick={() => setIsOpen(false)} size={20} color='#75f94c' className="absolute right-1 top-1" />
                                <li onClick={NavigateToviewprofile}>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <img className="mr-2 h-7 w-7" src={profileImagePreview || 'default-profile.jpg'} alt="profile" width={24} />
                                                Voir mon profil

                                            </a>
                                        </li>
                                        <li onClick={NavigateToChangePicture}>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaRegImage className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mettre à jour l'image

                                            </a>
                                        </li>
                                        <li onClick={NavigateToCard}>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaRegIdCard className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                carte de visite

                                            </a>
                                        </li>
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <IoIosNotifications className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Notifications

                                            </a>
                                        </li> */}
                                         {subscription && (
                                            <li>
                                            {/* <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes demandes

                                            </a> */}
                                        </li>
                                        )}
                                        <li>
                                            {/* <a
                                                href="view-requests.php"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                consulter votre demande

                                            </a> */}
                                        </li>
                                               {subscription && (
                                        <li onClick={NavigateToViewRequests}>
                                            <a
                                                href=""
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                voir la demande

                                            </a>
                                        </li>
                                             )}
                                             {subscription && (
                                        <li onClick={NavigateToViewAllRequests}>
                                            <a
                                                href=""
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                voir toutes les demandes

                                            </a>
                                        </li>
                                             )}
                                                   {subscription && (
                                        <li>
                                            <a
                                                href="https://btobjob.urbancodez.com/job.php"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaPoll  className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                ajouter un travail

                                            </a>
                                        </li>
                                                 )}
                                                 {subscription && (
                                        <li>
                                            <a
                                                href="https://btobjob.urbancodez.com/rental.php"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaCartPlus className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Article de location/vente

                                            </a>
                                        </li>
                                       )}
                                                  {subscription && (
                                        <li>
                                            <a
                                                href="https://btobjob.urbancodez.com/boost.php"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaArrowAltCircleUp className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Boostez votre profil

                                            </a>
                                        </li>
                                       )}
                                          {subscription && (
                                        <li>
                                            
                                        </li>
                                           )}
                                             {subscription && (
                                        <li onClick={NavigateToAddOffer}>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <HiSearchCircle className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                ajouter une offre

                                            </a>
                                        </li>
                                           )}
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <PiCurrencyCircleDollarFill className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mon Solde

                                            </a>
                                        </li> */}
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidCoupon className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes Coupouns

                                            </a>
                                        </li> */}
                                        <li onClick={NavigateToeditprofile}>
                                            <a
                                            
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaInfoCircle className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Information personnelles

                                            </a>
                                        </li>

                                        <li onClick={()=>(setOpenShare(!openShare))}>
                                            <a
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <IoMdShare className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Partager

                                            </a>
                                        </li>
                                        <li>
                                <a 
                                    href="https://btobjob.urbancodez.com/contact"
                                    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaFlag
                                    className="mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="flex-grow">rapport</span>
                                </a>
                                </li>
                                <li>
                                <button onClick={handleLogout} 
                                   
                                    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaSignOutAlt
                                    className="mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="flex-grow">Déconnexion</span>
                                </button>
                                </li>

                                        {/* <li>
                                            <a
                                                href="#"
                                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <MdBarChart className="mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes Reports

                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </nav>

            <PublicOrPrivate toggleboolean={openDiv} updateState={getDivState}/>
            <ProfileShare boolean={openShare}/>
            <Notifications boolean={openNotif} returnState={getNotifState}/>
            

        </>
    )
}

export default NavbarProfile;