import React, { useState, useEffect } from "react";
import ProfileContent from "../../assests/profileContent.png";
import UserProfile from "../../assests/profileuser.png";
import { MdOutlineEmail, MdCurrencyFranc, MdCancel } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Star from "../../assests/Star.png";
import { IoMdContacts } from "react-icons/io";
import { BiMessageRoundedDots } from "react-icons/bi";
import { IoBriefcaseOutline } from "react-icons/io5";
import Photo1 from "../../assests/photos1.png";
import Photo2 from "../../assests/photos2.png";
import Photo3 from "../../assests/photos3.png";
import Photo4 from "../../assests/photos4.png";
import { FaEnvelope } from "react-icons/fa";
import { FaHammer } from "react-icons/fa6";
import { RiFlag2Fill } from "react-icons/ri";
import MAP from "../../assests/map.png";
import PostingPicture from "../../assests/postingpicture.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Rate2 from "../ProfileView/Rate2";
import ProfileShare from "../ProfileView/ProfileShare";
import api from "../../services/api"; // Assuming you have an API service set up
import MessageModal from './MessageModal';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReviewsModal from './ReviewsModal';
import { AiOutlineShake } from "react-icons/ai";
import axios from "axios";
const MainProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openShare, setOpenShare] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [isOpenService, setIsOpenService] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isReviewsModalOpen, setIsReviewsModalOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [posts, setPosts] = useState([]);
  const [likesStatus, setLikesStatus] = useState({});
  const openReviewsModal = () => setIsReviewsModalOpen(true);
  const closeReviewsModal = () => setIsReviewsModalOpen(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rate, setRate] = useState({ minPrice: 0, maxPrice: 0 });
  const [photos, setPhotos] = useState([]);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const reviews = [
    { author: 'User1', text: 'Great service!' },
    { author: 'User2', text: 'Very professional.' },
    { author: 'User3', text: 'Highly recommended!' },
    // Add more reviews as needed
  ];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const professionalId = queryParams.get("professional_id");
  const navigate = useNavigate();
  const [ratingData, setRatingData] = useState({ avg_rating: 0, total_reviews: 0 });
  const handleLike = async (postId) => {
    try {
      const response = await axios.post('https://btobjob.urbancodez.com/like-post.php', { postId }, { withCredentials: true });
      setLikesStatus(prevStatus => ({
        ...prevStatus,
        [postId]: response.data.liked
      }));
    } catch (error) {
      console.error('Error liking the post:', error);
    }
  };

  useEffect(() => {
    if (!professionalId) return; // Avoid fetching if the ID is not available

    const fetchImages = async () => {
      try {
        // Fetch profile and cover images using professional ID
        const profileData = await api.fetchProfileImagebyid(professionalId);
        const coverData = await api.fetchCoverImagebyid(professionalId);

        // Set the image previews in the state
        setProfileImagePreview(profileData.imageUrl);
        setCoverImagePreview(coverData.imageUrl);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [professionalId]);
  useEffect(() => {
    const fetchPhotos = async () => {
        try {
            // Check if professionalId is available
            if (!professionalId) {
                throw new Error('Professional ID is missing');
            }

            const response = await axios.get(`https://btobjob.urbancodez.com/get_photos_specific.php?professional_id=${professionalId}`, { withCredentials: true });
            
            if (Array.isArray(response.data)) {
                setPhotos(response.data.slice(0, 4)); // Limit to 4 photos
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error("Error fetching photos:", error);
            setError("Error fetching photos");
        } finally {
            setLoading(false);
        }
    };

    fetchPhotos();
}, [professionalId]); // Depend on professionalId
useEffect(() => {
  const fetchRates = async () => {
    if (!professionalId) {
      console.error('No professional_id provided in the URL');
      setError('No professional_id provided');
      setLoading(false);
      return;
    }

    try {
      // Append professional_id to the API request URL
      const response = await axios.get(`https://btobjob.urbancodez.com/fetchRates_speecific.php?professional_id=${professionalId}`, { withCredentials: true });
      setRate(response.data);
    } catch (error) {
      console.error('Error fetching rates:', error);
      setError('Error fetching rates');
    } finally {
      setLoading(false);
    }
  };

  fetchRates();
}, [professionalId]);
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await api.fetchProfileById(professionalId);
        setProfileData(response);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (professionalId) {
      fetchProfileData();
    } else {
      setIsLoading(false);
    }
  }, [professionalId]);
  const toggleShare = () => {
    setOpenShare((prevState) => !prevState);
  };
  const checkLikeStatus = async (postId) => {
    try {
      const response = await axios.post('https://btobjob.urbancodez.com/check-like.php', { postId }, { withCredentials: true });
      return response.data.liked;
    } catch (error) {
      console.error('Error checking like status:', error);
      return false;
    }
  };
  const fetchSpecificPosts = async (professionalId) => {
    try {
      const postsData = await api.fetchSpecificPosts(professionalId);
      setPosts(postsData);

      // Check like status for each post
      const likesPromises = postsData.map(post => checkLikeStatus(post.id));
      const likesResults = await Promise.all(likesPromises);
      
      const likesStatus = {};
      postsData.forEach((post, index) => {
        likesStatus[post.id] = likesResults[index];
      });
      setLikesStatus(likesStatus);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  const navigateToReviews = () => {
    const reviewsUrl = `https://btobjob.urbancodez.com/reviews.php?professional_id=${professionalId}`;
    window.location.href = reviewsUrl;
};
useEffect(() => {
  const fetchRatings = async () => {
    if (!professionalId) return; // If professionalId is not available, exit early

    try {
      const response = await axios.get('https://btobjob.urbancodez.com/get-user-reviews-specific.php', {
        params: { professional_id: professionalId }, // Pass professionalId as a query parameter
        withCredentials: true
      });
      const data = response.data;

      // Ensure avg_rating is a number and handle possible undefined/null
      const avgRating = data.avg_rating !== null && data.avg_rating !== undefined
        ? parseFloat(data.avg_rating)
        : 0;

      setRatingData({
        avg_rating: avgRating,
        total_reviews: data.total_reviews || 0
      });
    } catch (error) {
      console.error('Error fetching ratings:', error);
      setError('Error fetching ratings');
    } finally {
      setLoading(false);
    }
  };

  fetchRatings();
}, [professionalId]); // Include professionalId in the dependency array

const avgRating = ratingData.avg_rating ? ratingData.avg_rating.toFixed(1) : '0.0';
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const professionalId = urlParams.get('professional_id');
    fetchSpecificPosts(professionalId);
  }, []);
  const toggleRate = () => {
    setOpenRate((prevState) => !prevState);
    setTimeout(() => {
      setOpenRate(false);
    }, 20000);
  };
 
  const NavigateToeditprofile = () => {
    navigate("/profile/editprofile");
  };

  const NavigateTochat = () => {
    setIsMessageModalOpen(true);
  };

  const NavigateToallphotos = () => {
    navigate(`/profile/ProfilePhotos?professional_id=${professionalId}`);
  };

  const toggleDropdownForService = () => {
    setIsOpenService((prevState) => !prevState);
  };


  const handleSendMessage = async (message) => {
    try {
      const response = await api.sendMessagee({
        receiverId: professionalId,
        message: message
      });
      console.log("Message sent response:", response);
      navigate(`/profile/chat`);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Profile data not found.</div>;
  }

  return (
    <>
      <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
        <div>
          <img  src={coverImagePreview || 'default-cover.jpg'} className="w-full object-cover h-48" />
        </div>
        <div className="flex flex-col bg-white pb-6 relative">
          <div className="flex justify-between py-1 px-1 bg-white relative mb-4">
            <div className="flex relative ">
              <img
        className="w-24 h-24 object-cover rounded-full border-white"
                src={profileImagePreview || 'default-profile.jpg'}
                alt="profile"
                
              />
              <div className="flex flex-col items-start">
                <span className="user-name">{profileData ? profileData.Name: 'Loading...'}</span>
                <span className="user-email">@{profileData.username}</span>
              </div>
            </div>
            <div className="flex flex-col absolute right-2">
              <div className="flex flex-row items-center justify-center gap-1">
                <button
                  onClick={toggleShare}
                  className="w-full cursor-pointer text-[10px] bg-white rounded-xl border-2 border-[#65A406] text-[#65A406] p-2"
                >
                  Partager
                </button>
                {/* <button onClick={NavigateToeditprofile} className="sm:w-full cursor-pointer text-[10px] w-[140px]  bg-white rounded-xl border-2 border-[#65A406] text-[#65A406]">
                  Editer le profil
                </button> */}
              </div>

              {/* <Toggler /> */}
            </div>
            <div></div>
          </div>

          <div className="fle flex-col ml-10">
            <div className="flex  items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <CiLocationOn className="location-email-icon" />
              <p>{profileData.Address}, {profileData.city} , {profileData.zip}</p>
            </div>
            <div className="flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <AiOutlineShake className="location-email-icon" />        <p>{profileData.Phone}</p>
            </div>
            <div className="flex  items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <MdOutlineEmail className="location-email-icon" />
              <p>{profileData.Email}</p>
            </div>
          </div>
          <div className="absolute"></div>
        </div>
        {/* Zain req */}
        {/* <div className="bg-white border-t-2">
        <ul className="purple-list">
          <li className="purple-listing">Aperçu</li>
          <li className="purple-listing">Demandes</li>
          <li className="purple-listing">Activité</li>
          <li className="purple-listing">Avis</li>
          <li className="purple-listing">Des photos</li>
        </ul>
      </div> */}
        {/* main div */}
        <div className="flex flex-col-reverse sm:flex-row justify-between mt-5">
          
          <div className="grid grid-cols-2 sm:flex sm:flex-col gap-2">
            <div
              className="flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
              onClick={navigateToReviews}
            >
              <p className="flex items-center gap-1">
                {avgRating} <img src={Star} className="w-3 h-3" alt="Star" />
              </p>
              <p className="div1-para-reviews">{ratingData.total_reviews} Reviews</p>
            </div>
    
            {/* 2nd */}
            {/* <div className="flex flex-col  justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 pl-4">
              <div className="flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoMdContacts className="w-5 h-5" />
                <div className="flex flex-col text-black">
                  <p className="font-semibold">38,47,154</p>
                  <p>Connexions</p>
                </div>
              </div>
              <div className="flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <BiMessageRoundedDots className="w-5 h-5" />
                <div className="flex flex-col text-black">
                  <p className="font-semibold">70%</p>
                  <p> Temps de réponse</p>
                </div>
              </div>
              <div className="flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoBriefcaseOutline className="w-5 h-5" />
                <div className="flex flex-col text-black">
                  <p className="font-semibold">3324</p>
                  <p>Emploi en direct</p>
                </div>
              </div>
            </div> */}
            {/* 3rd */}
            <div className="flex flex-col justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 px-2 py-2">
            <div className="flex justify-between">
                <p className="photos-para">Photos</p>
                <p className="seeall-para cursor-pointer" onClick={NavigateToallphotos}>Voir tout</p>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="grid grid-cols-2 gap-2">
                    {photos.map((photo, index) => (
                        <img
                            key={index}
                            src={`https://btobjob.urbancodez.com/${photo.photo_url}`}
                            width={70}
                            className="w-full object-contain"
                            alt={`User Photo ${index + 1}`}
                        />
                    ))}
                </div>
            )}
        </div>
            {/* 4th */}
            <div className="flex flex-col gap-2 justify-center items-start bg-white px-2 py-2 rounded-xl mt-3 w-full sm:w-[150px]">
            <div>
      {/* Your profile component layout */}
      <button onClick={NavigateTochat}>Send Message</button>

      <MessageModal
        isOpen={isMessageModalOpen}
        onClose={() => setIsMessageModalOpen(false)}
        onSend={handleSendMessage}
      />
    </div>
            
    <div className="flex items-center justify-center gap-2 text-md">
                <MdCurrencyFranc className="" size={20} />
                <p className="div2-para">   Les taux: {rate.minPrice} - {rate.maxPrice}</p>
              </div>

             
              {/* <div className={`${user?"flex":"hidden"} cursor-pointer`} onClick={toggleRate}>
                <div className="flex items-center justify-center gap-2 text-md">
                  <FaEdit className="" />
                  <p className="div2-para">Modifier le tarif</p>
                </div>
              </div> */}


              <div className="flex items-center "></div>
              <div className="flex items-center"></div>
            </div>
          </div>
          {/* right 👇🏻 */}
          <div className="flex flex-col gap-2 w-full sm:ml-4">
            <div className="flex items-center justify-center w-full  py-2 rounded-xl">
          
            </div>
            <div className="w-full h-80">
        <MapContainer center={[profileData.latitude, profileData.longitude]} zoom={13} style={{ height: "100%", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[profileData.latitude, profileData.longitude]}>
            <Popup>
              {profileData.name} <br /> {profileData.email}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      <div className="flex flex-col gap-1">
    {posts.map((post) => (
      <div key={post.id} className="bg-white p-8 shadow-md rounded-lg w-full">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <img
              src={post.profile_picture}
              alt="User Avatar"
              className="w-8 h-8 rounded-full"
            />
            <div>
              <p className="text-gray-800 font-semibold">{post.name}</p>
              <p className="text-gray-500 text-sm">{post.created_at}</p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-gray-800">{post.text}</p>
        </div>
        <div className={`mb-4 ${post.images.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
          {post.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Post Image ${index + 1}`}
              className="w-full h-48 object-cover rounded-md mb-2"
            />
          ))}
        </div>
        <div className="flex items-center justify-between text-gray-500">
          <div className="flex items-center space-x-2">
          <button
                className={`flex justify-center items-center gap-2 px-2 hover:bg-gray-50 rounded-full p-1 ${likesStatus[post.id] ? 'text-red-600' : 'text-gray-500'}`}
                onClick={() => handleLike(post.id)}
              >
                <svg
                  className={`w-5 h-5 ${likesStatus[post.id] ? 'fill-current text-red-600' : 'fill-gray-400'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 21.35l-1.45-1.32C6.11 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-4.11 6.86-8.55 11.54L12 21.35z" />
                </svg>
                {likesStatus[post.id] ? 'Liked' : 'Like'}
              </button>
            </div>
          </div>
        </div>
      ))}



            </div>
          </div>
        </div>
      </div>
      <ProfileShare boolean={openShare} />
      <Rate2 boolean={openRate}/>
    </>
  );
};

export default MainProfile;
