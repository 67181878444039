import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import userProfile from "../../assests/people2.png";
import { BiHide } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const ChangePassword = () => {
  const navigate = useNavigate();
  const NavigateToBack = () => {
    navigate(-1); // Navigates back one step in the history stack
};
  return (
    <div className="mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg mt-16 md:px-0 px-2">
      <div className="flex flex-col">
        <div className="p-6 border-b-2 flex flex-col">
          <div className="flex items-center">
            <FaArrowLeft onClick={NavigateToBack} className="h-6 w-6 pr-2" />
            <p className="text-xl font-semibold">Changez votre mot de passe</p>
          </div>
          <p>modifier vos informations</p>
        </div>
        <div className="flex items-center justify-center mt-7">
          <div className="flex flex-col items-center justify-center">
            <img className="" src={userProfile} alt="profile" width={100} />
            <div className="flex flex-col items-center justify-center">
              <span className="font-bold text-xl">Carlos Sainz</span>
              <span className="">@imjituchauhan</span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2 max-w-2xl mt-5 px-8 mb-10">
          <div className="flex flex-col gap-5 ">
            <div className="relative mb-3" data-twe-input-wrapper-init>
              <input
                type="password"
                maxLength={30}
                className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                id="exampleFormControlInput1"
                placeholder="Example label"
              />
              <label
                for="exampleFormControlInput1"
                className="pointer-events-none absolute left-3 -top-3 text-lime-500 mb-0 max-w-[90%] "
              >
                Tapez votre mot de passe actuel
              </label>
              <BiHide
                className="absolute right-2 top-2 text-gray-500"
                size={24}
              />
              <p className="text-red-500">Mot de passe incorrect</p>
            </div>

            <div className="relative mb-3" data-twe-input-wrapper-init>
              <input
                maxLength={30}
                type="password"
                className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                id="exampleFormControlInput1"
                placeholder="Example label"
              />
              <label
                for="exampleFormControlInput1"
                className="pointer-events-none absolute left-3 -top-3 text-lime-500 mb-0 max-w-[90%] "
              >
                nouveau mot de passe
              </label>
              <BiHide
                className="absolute right-2 top-2 text-gray-500"
                size={24}
              />
              <p className="text-red-500">
                Doit contenir au moins 8 caractères
              </p>
            </div>
            <div className="relative mb-3" data-twe-input-wrapper-init>
              <input
                type="password"
                maxLength={30}
                className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                id="exampleFormControlInput1"
                placeholder="Example label"
              />
              <label
                for="exampleFormControlInput1"
                className="pointer-events-none absolute left-3 -top-3 text-lime-500 mb-0 max-w-[90%] "
              >
                Entrez à nouveau le mot de passe
              </label>
              <FaEye
                className="absolute right-2 top-2 text-gray-500"
                size={24}
              />
              <p className="text-red-500">Doit contenir au moins</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mb-20">
          <button className="w-[200px] rounded-xl bg-gradient-to-r text-white from-lime-400 to-lime-600 px-2 py-2 flex items-center justify-center">
            {" "}
            Confirme
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
