import NavbarProfile from "./Navbarprofile";
import "./login.css";
import Offerpage from "./OfferPage/Offerpage";
import Allthemes1 from "./AllThemes/Allthemes1";
import Request from "./RequestPage/Request";
import MainProfile from "./ProfileView/MainProfile";
import ChatApp from "./ChatApp";
import ProfileEdit from "./ProfileView/ProfileEdit";
import ChangePassword from "./ProfilePage/ChangePassword";
import Verifyme from "./ProfilePage/Verifyme";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BuyAWebsite from "./WebsitePage/BuyAWebsite";
import BuyAWebsite2 from "./WebsitePage/BuyAWebsite2";
import PostServices from "./Services/PostServices";
import SubscriptionPage from "../subscription/Subscription";
import ForumHome from "./ForumPage/ForumHome";
import AllPhotos from "./ProfileView/AllPhotos";
import Allthemes2 from "./OfferPage/Allthemes2";
import SpecificUserProfile from "./OfferPage/SpecificUserProfile";
import ServicesAndRental from "./AllThemes/ServicesAndRental";
import FooterSection from "../components/Footer";
import JobDescription from './Services/JobDescription';
import ViewRequests from "./ProfileView/requests";
import AddOffer from "./ProfileView/addoffer";
import AddForum  from "./ForumPage/addnewforum";
import ForumDetail from './ForumPage/ForumDetail';
import PrivateRequest from "./ProfileView/privaterequest";
import UpdatePicturePage from "./ProfileView/changepicture";
import Conversation from "./conversation"
import BusinessCard from "./ProfileView/card";
import ProfilePhotos from "./OfferPage/profilephotos";
import Ads from "./ads/ads";
import AdsDetails from "./ads/details";
import ViewAllRequests from "./ProfileView/allrequests";
function ProfileUser() {
    return (
        <div className="overflow-x-hidden">
            <NavbarProfile />
            <Routes>
                <Route path="offers" element={<Offerpage />} />
                <Route path="request" element={<Request />} />
                <Route path="subscription" element={<SubscriptionPage />} />
                <Route path="chat" element={<ChatApp />} />
                <Route path="forum" element={<ForumHome />} />
                <Route path="website" element={<BuyAWebsite />} />
                <Route path="viewprofile" element={<MainProfile />} />
                <Route path="editprofile" element={<ProfileEdit />} /> 
                <Route path="verify" element={<Verifyme />} /> 
                <Route path="allphotos" element={<AllPhotos />} />
                <Route path="allthemes" element={<Allthemes2/>} /> 
                <Route path="Allacategories" element={<Allthemes1/>} />
                <Route path="specificUser" element={<SpecificUserProfile/>} />  
                <Route path="services_rentals" element={<ServicesAndRental/>} />  
                <Route path="specificwebsite/:id" element={<BuyAWebsite2/>} /> 
                <Route path="changepassword" element={<ChangePassword/>} /> 
                <Route path="services" element={<PostServices/>} /> 
                <Route path="jobdescription/:jobId" element={<JobDescription />} />
                <Route path="addoffer" element={<AddOffer />} /> 
                <Route path="viewrequests" element={<ViewRequests />} /> 
                <Route path="AddForum" element={< AddForum />} /> 
                <Route path="privaterequest" element={<PrivateRequest />} />
                <Route path="ForumDetail/:id" element={<ForumDetail />} />
                <Route path="updatePicturepage" element={<UpdatePicturePage />} />
                <Route path="conversation" element={<Conversation />} /> 
                <Route path="businesscard" element={<BusinessCard />} /> 
                <Route path="profilePhotos" element={<ProfilePhotos />} /> 
                <Route path="ads" element={<Ads/>} /> 
                <Route path="AdsDetails" element={<AdsDetails/>} /> 
                <Route path="ViewAllRequests" element={<ViewAllRequests/>} />
            </Routes>
    
            <FooterSection/>
        </div>
    );
}

export default ProfileUser;
