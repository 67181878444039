import React, { useState, useEffect } from 'react';
import LOGO from '../assests/websitelogo.png';
import './style.css';
import '../tailwind.css';
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom';
import USER from '../assests/user.png';
import api from '../services/api';

function NavbarSection() {
    const [nav, setNav] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const response = await api.getSession();
                if (response.user) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error fetching session:', error);
                setIsLoggedIn(false);
            }
        };

        fetchSession();
    }, []);

    const handleToggleNav = () => {
        setNav(!nav);
    };

    const handleLogout = async () => {
        try {
            await api.logout();
            setIsLoggedIn(false);
            setNav(false);
            window.location.reload();
        } catch (error) {
            console.error('Logout error', error);
        }
    };

    return (
        <div>
            <div className="mx-auto w-full bg-[#fff] px-6 lg:px-8">
                <div className="header-bar">
                    <div className="menu-bar flex justify-end items-center w-full">
                        {/* <button className=" hidden md:flex  professional-navbar">
                            <Link className="professional-navbar-link" to="/professionaluser">Se Connecter Particulier</Link>
                        </button> */}
                        <ul className="flex flex-wrap items-center ml-auto justify-content-end">
                            <li className="flex">
                                <AiOutlineMenu size={34} className="cursor-pointer" onClick={handleToggleNav} />
                            </li>
            
                            {/* {isLoggedIn ? (
                                <li className="hidden md:flex items-center gap-1 ml-auto justify-content-end d-flex">
                                    <Link className="navbar-link" to="/profile/viewprofile">Profil</Link>
                                    <button className="professional-navbar" onClick={handleLogout}>
                                        Déconnexion
                                    </button>
                                </li>
                            ) : (
                                <li className="hidden md:flex items-end gap-1 ">
                                    <button className="professional-navbar">
                                        <Link className="professional-navbar-link" to="/professionaluser">Se Connecter Professionnel</Link>
                                    </button>
                                </li>
                            )} */}
                        </ul>
                    </div>
                </div>
            </div>
            {nav && (
                <div className="fixed top-0 left-0 z-10 h-full w-full bg-black/50" onClick={handleToggleNav}></div>
            )}
            <div className={`fixed top-0 ${nav ? "right-0" : "right-[-300px]"} duration-300 z-50 w-[200px] h-full bg-lime-200 flex flex-col items-center px-2 py-6`}>
                <div className="flex flex-row gap-11 items-center">
                    <AiOutlineClose size={15} onClick={handleToggleNav} />
                </div>
                <div className="my-8">
                    <ul className="flex flex-col items-center gap-y-6 font-poppins font-normal text-[10px] tracking-wide ml-1 cursor-pointer">
                        <li>
                            <Link className="navbar-link" to="/">Accueil</Link>
                        </li>
                        <li>
                            <Link className="navbar-link" to="/profile/services">Services</Link>
                        </li>
                        <li>
                            <Link className="navbar-link" to="/about">Nous</Link>
                        </li>
                        <li>
                            <Link className="navbar-link" to="/contact">Contact</Link>
                        </li>
                        <li>
                            <Link className="navbar-link" to="/faqs">FAQ</Link>
                        </li>
                        {!isLoggedIn && (
                            <li>
                                <button className="professional-navbar">
                                    <Link className="professional-navbar-link" to="/professionaluser">Se Connecter Professionnel</Link>
                                </button>
                            </li>
                        )}
                        {isLoggedIn ? (
                            <li className="last-list">
                                <Link className="navbar-link" to="/profile/viewprofile">Profil</Link>
                                <button className="professional-navbar" onClick={handleLogout}>
                                    Déconnexion
                                </button>
                            </li>
                        ) : (
                            <li className="last-list">
                                <button className="   professional-navbar">
                                    <Link className="professional-navbar-link" to="/professionaluser">Se Connecter Particulier</Link>
                                </button>

                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default NavbarSection;
