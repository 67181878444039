// src/components/PostServices.jsx
import React, { useEffect, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { IoLayersOutline } from "react-icons/io5";
import { TfiAnnouncement } from "react-icons/tfi";
import { CiVideoOn, CiLocationOn } from "react-icons/ci";
import { MdWorkOutline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

const PostServices = () => {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [workPlaceQuery, setWorkPlaceQuery] = useState(''); // New state for workplace search
    const [selectedCategory, setSelectedCategory] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await api.fetchJobs();
                setJobs(response.jobs);
                setFilteredJobs(response.jobs); // Initially, display all jobs
                setCategories(response.categories);
            } catch (error) {
                console.error("Error fetching jobs:", error);
                setError(error.message || 'Error fetching jobs');
            }
        };
        fetchJobs();
    }, []);

    useEffect(() => {
        const filtered = jobs.filter(job => {
            const jobTitle = job.job_title ? job.job_title.toLowerCase() : '';
            const description = job.description ? job.description.toLowerCase() : '';
            const workPlace = job.work_place ? job.work_place.toLowerCase() : '';
            const companyName = job.company ? job.company.toLowerCase() : '';
            const searchLower = searchQuery.toLowerCase();
            const workPlaceLower = workPlaceQuery.toLowerCase(); // Lowercased workplace query
    
            const matchesSearch = jobTitle.includes(searchLower) || description.includes(searchLower) || companyName.includes(searchLower);
            const matchesWorkPlace = workPlace.includes(workPlaceLower);
            const matchesCategory = selectedCategory ? job.category === selectedCategory : true; // Updated to match 'category' field
    
            return matchesSearch && matchesWorkPlace && matchesCategory;
        });
    
        setFilteredJobs(filtered);
    }, [searchQuery, workPlaceQuery, selectedCategory, jobs]); // Add workPlaceQuery dependency

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleWorkPlaceChange = (event) => {
        setWorkPlaceQuery(event.target.value);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category); // Set selected category to trigger filtering
    };

    const navigateToJobDescription = (jobId) => {
        navigate(`/profile/jobdescription/${jobId}`);
    };

    const categoryIcons = {
        'Internship': MdWorkOutline,
        'Full Time': IoLayersOutline,
        'Part Time': TfiAnnouncement,
        'Learning': CiVideoOn
    };

    return (
        <div className='flex flex-col max-w-8xl items-start gap-10 mx-auto p-12'>
            <div className='w-full md:flex grid grid-cols-1 gap-4 bg-white px-2 justify-between py-2'>
                <div className='flex items-center md:w-1/2 w-full'>
                    <FaSearch className="text-blue-400" />
                    <input
                        type="text"
                        placeholder='Rechercher par nom, description, etc.'
                        className="outline-none px-2 py-2 w-full text-base"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className='flex items-center md:w-1/2 w-full'>
                    <CiLocationOn className="text-blue-400" />
                    <input
                        type="text"
                        placeholder='Rechercher par lieu de travail'
                        className="outline-none px-2 py-2 w-full text-base"
                        value={workPlaceQuery}
                        onChange={handleWorkPlaceChange}
                    />
                </div>
                <div className="flex gap-2">
                    <button
                        className='text-xs font-semibold bg-[#65A406] text-white px-2 py-2 rounded-md'
                    >
                        Find Job
                    </button>
                </div>
            </div>

            <p className='sm:text-3xl text-xl font-bold'>Explorer par <span className='text-[#65A406]'>catégorie</span></p>

            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full'>
                {categories.map((category, index) => {
                    const IconComponent = categoryIcons[category.category] || MdWorkOutline;
                    return (
                        <div key={index} className='flex items-center gap-2 bg-white rounded-lg p-4 cursor-pointer' onClick={() => handleCategoryClick(category.category)}>
                            <IconComponent size={36} color='#65A406' />
                            <div className='flex flex-col items-start'>
                                <p className='font-bold text-xl'>{category.category}</p>
                                <p>{category.job_count} emplois disponibles</p>
                            </div>
                        </div>
                    );
                })}
            </div>

            {error && <p className="text-red-500">{error}</p>}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-x-4 gap-y-12'>
                {filteredJobs.map((job, index) => (
                    <div onClick={() => navigateToJobDescription(job.ID)} key={index} className="mx-auto bg-white w-full shadow-lg rounded-lg overflow-hidden p-4">
                        <div className="px-6 py-4">
                            <h2 className="text-xl font-bold text-gray-800">{job.job_title}</h2>
                            <p className="text-gray-600">{job.category}</p>
                            <div className="flex items-center mt-2">
                                <span className="px-2 py-1 text-xs font-semibold leading-tight text-green-700 bg-green-100 rounded-full">{job.job_type.toUpperCase()}</span>
                                <span className="ml-2 text-gray-600">Salary: {job.salary}€</span>
                            </div>
                            <div className="flex items-center mt-4">
                            <img className="w-10 h-10 object-cover rounded-full" src={job.ProfilePicture} alt="company logo" />

                                <div className="ml-4">
                                    <p className="text-gray-800 font-semibold">{job.company}</p>
                                    <div className='flex items-center'>
                                        <CiLocationOn />
                                        <p className="text-gray-600">{job.work_place}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PostServices;
