import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import CommentList from './CommentList';

const ForumDetail = () => {
  const { id } = useParams();
  const [topic, setTopic] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    const fetchTopicAndComments = async () => {
      try {
        const [topicResponse, commentsResponse] = await Promise.all([
          api.fetchForumTopicById(id),
          api.fetchComments(id)
        ]);
        setTopic(topicResponse);
        setComments(commentsResponse || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTopicAndComments();
  }, [id]);

  const handleCommentSubmit = () => {
    const commentData = { topic_id: id, comment: newComment };

    api.submitComment(commentData)
      .then(response => {
        if (response.success) {
          const newCommentObject = {
            id: response.id,
            comment: newComment,
            professional_name: response.professional_name // Assuming API returns professional name
          };
          setComments([...comments, newCommentObject]);
          setNewComment('');
        } else {
          console.error('Error submitting comment:', response.message);
        }
      })
      .catch(error => {
        console.error('Error submitting comment:', error);
      });
  };

  if (!topic) {
    return <p>Loading...</p>;
  }

  const imagesArray = typeof topic.images === 'string' ? topic.images.split(',') : [];

  const renderImages = imagesArray.length > 0 ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {imagesArray.map((image, index) => (
        <img key={index} src={`https://btobjob.urbancodez.com/${image}`} alt={`Image ${index}`} className="h-100 w-full object-contain rounded-md" />
      ))}
    </div>
  ) : (
    <p>No images available</p>
  );

  return (
    <div className="md:mx-auto m-2 max-w-6xl md:mt-12 rounded-lg">
      <div className="bg-white rounded-md p-4">
        <p className="text-2xl font-bold mb-4">{topic.heading}</p>
        <p className="mb-4">{topic.description}</p>
        {renderImages}
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-2">Comments</h3>
          <CommentList comments={comments} />
        </div>
        <div className="mt-4">
          <h4 className="text-lg font-bold mb-2">Add a Comment</h4>
          <textarea
            className="w-full p-2 border rounded-md"
            rows="4"
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
          />
          <button
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={handleCommentSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForumDetail;
