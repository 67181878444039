
import './style.css'
import LOGO from '../assests/b2bjob logo 1.png'
import { FaInstagram, FaFacebookF, FaLinkedin } from "react-icons/fa";
import api from '../services/api';
import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import React, { useState, useEffect } from 'react';


function FooterSection() {
  const [nav, setNav] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response.user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching session:', error);
        setIsLoggedIn(false);
      }
    };

    fetchSession();
  }, []);

  const handleToggleNav = () => {
    setNav(!nav);
  };

  const handleLogout = async () => {
    try {
      await api.logout();
      setIsLoggedIn(false);
      setNav(false);
      window.location.reload();
    } catch (error) {
      console.error('Logout error', error);
    }
  };

  return (
    <div className='mx-auto mt-24 max-w-7xl px-6 sm:mt-40 lg:px-8 mb-9 bg-[#FFF]'>

      <div className='flex flex-row justify-between items-center' >
        <div><img className='' src={LOGO} width={120} alt="B2BJob Logo" /></div>
        <div>
          <h4 className='text-lime-600 md:text-2xl font-semibold'>Contact</h4>
          <p className='md:text-xl font-medium'>info@btobjob.com</p>
        </div>
      </div>

      <div className="flex justify-center">
        <ul className="flex flex-wrap items-center space-x-4">
          {/* <li className="flex md:hidden">
              <AiOutlineMenu size={24} className="cursor-pointer" onClick={handleToggleNav} />
            </li> */}
          <li className="hidden md:flex">
            <Link className="navbar-link" to="/">Accueil</Link>
          </li>
          <li className="hidden md:flex">
            <Link className="navbar-link" to="/profile/services">Abonements</Link>
          </li>
          <li className="hidden md:flex">
            <Link className="navbar-link" to="/about">Nous</Link>
          </li>
          <li className="hidden md:flex">
            <Link className="navbar-link" to="/contact">Contact</Link>
          </li>
          <li className="hidden md:flex">
            <Link className="navbar-link" to="/faqs">FAQ</Link>
          </li>
          <li className="hidden md:flex">
            <Link className="navbar-link" to="/faqs">CGV</Link>
          </li>
          <li className={`flex md:hidden items-center ${isLoggedIn ? 'ml-auto' : ''}`}>
            <Link className="navbar-link" to="/professionaluser">S'inscrire</Link>
          </li>
        </ul>
      </div>
      <div className='footer-icons'>
        <a href="https://www.facebook.com/btobjob.fr" target="_blank" rel="noopener noreferrer"><FaFacebookF size={30} /></a>
        <a href="https://www.instagram.com/btob.job?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer"><FaInstagram size={30} /></a>
        <a href="https://www.linkedin.com/company/b-to-b-job/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} /></a>
      </div>
      {/* <div className="footer-last-section">
        <p className="footer-last-section-para">© 2024 btobjob.com. Conditions générales / Politique en matière de cookies / Politique de confidentialité</p>
      </div> */}
    </div>

  )
}

export default FooterSection;
