import React, { useState, useEffect } from 'react';
import MultiRangeSlider from '../MultiRangeSlider';
import axios from 'axios';

const Rate2 = ({ boolean }) => {
    const [localToggle, setLocalToggle] = useState(boolean);

    // State for MultiRangeSlider
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        if (localToggle) {
            console.log('Form has been toggled. Fetching recommendations...');
            // Fetch recommendations or perform any other necessary actions
        }
    }, [localToggle]);

    const handleSave = async () => {
        console.log(`Price range set to: ${minPrice} - ${maxPrice}`);

        try {
            const response = await axios.post('https://btobjob.urbancodez.com/storePrice.php', {
                minPrice,
                maxPrice
            }, { withCredentials: true });

            console.log(response.data.message);
        } catch (error) {
            console.error('Error saving data:', error);
        }

        setLocalToggle(false);
    };

    const handleCancel = () => {
        setLocalToggle(false);
    };

    return (
        <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-10 bg-black/50 z-[9999]`}>
            <div className="bg-white rounded-md shadow-md p-4">
                <h2 className="text-lg font-bold mb-4">Définissez votre durée</h2>
              
                <h3 className="text-lg font-bold mt-6">Échelle des prix</h3>
                <div className='mt-4 mb-6'>
                    <MultiRangeSlider
                        min={0}
                        max={1000}
                        onChange={({ min, max }) => {
                            setMinPrice(min); // Update min price state
                            setMaxPrice(max); // Update max price state
                        }}
                    />
                </div>
                <button
                    className="bg-[#65A406] hover:bg-[#65A406] text-white font-bold py-2 px-4 rounded-md mt-4"
                    onClick={handleSave} // Handle save button click for second save button
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    )
}

export default Rate2;
