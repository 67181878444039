import React, { useState, useEffect } from 'react';
import { IoMdLock } from "react-icons/io";
import rectangle from "../../assests/Rectangle 146.png";
import api from '../../services/api'; // Adjust the path as necessary
import { useParams } from 'react-router-dom'; // Import useParams


const BuyAWebsite2 = () => {
    const [websites, setWebsites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null); // Initially set to null
    const [isProfessional, setIsProfessional] = useState(false); // Initially set to false
    const { id: websiteId } = useParams(); // Extract the websiteId from URL parameters
    const [purchaseStatus, setPurchaseStatus] = useState('buy'); // Default status is 'buy'
    const [zipFile, setZipFile] = useState(''); // To store the zip file path

    useEffect(() => {
        const fetchWebsites = async () => {
            try {
                const data = await api.fetchWebsites();
                setWebsites(data);
            } catch (error) {
                console.error('Error fetching websites:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchSession = async () => {
            try {
                const sessionData = await api.getSession();
                setUserId(sessionData.user.id); // Assuming session data includes user ID
                setIsProfessional(sessionData.user.professional); // Assuming session data includes professional status
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };

        const fetchPurchaseStatus = async () => {
            if (websiteId && userId) {
                try {
                    const response = await fetch(`https://btobjob.urbancodez.com/website_purchase.php?id=${websiteId}`, {
                        credentials: 'include'
                    });
                    const data = await response.json();
                    if (data.status) {
                        setPurchaseStatus(data.status);
                        if (data.zipFile) {
                            setZipFile(data.zipFile);
                        }
                    } else {
                        console.error('Error fetching purchase status:', data.error);
                    }
                } catch (error) {
                    console.error('Error fetching purchase status:', error);
                }
            }
        };

        fetchWebsites();
        fetchSession();
        fetchPurchaseStatus();
    }, [websiteId, userId]);

    const handleBuyOrDownload = (title, price) => {
        if (purchaseStatus === 'buy') {
            if (userId !== null && websiteId !== null) {
                const url = `https://btobjob.urbancodez.com/payment-website.php?title=${encodeURIComponent(title)}&price=${encodeURIComponent(price)}&userId=${userId}&professional=${isProfessional}&id=${websiteId}`;
                window.location.href = url;
            } else {
                console.error('User ID or Website ID is not set');
            }
        } else if (purchaseStatus === 'download') {
            if (zipFile) {
                const url = `https://btobjob.urbancodez.com/admin/backend/${zipFile}`;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', zipFile);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Zip file path is not set');
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className='flex flex-col justify-center items-center'>
                <div className='w-full relative'>
                    <img src={rectangle} className='w-full object-contain' alt="Rectangle background" />
                    {websites.length > 0 && (
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <p className='text-2xl font-bold text-white'>{websites[0].title}</p>
                            <p className='text-white'>{websites[0].link}</p>
                            <p className='text-white'>{websites[0].price}€</p>
                        </div>
                    )}
                    <IoMdLock className='absolute bottom-4 right-4 font-bold' size={30} color='white' />
                </div>

                {websites.length > 0 && (
                    <div className="flex items-center justify-center my-4">
                        <button 
                            className="rounded-2xl w-fit bg-gradient-to-r text-white from-lime-400 to-lime-600 px-3 py-2 flex items-center justify-center"
                            onClick={() => handleBuyOrDownload(websites[0].title, websites[0].price)}
                        >
                            {purchaseStatus === 'buy' ? 'Achetez pour débloquer' : 'Télécharger'}
                        </button>
                    </div>
                )}

                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-6xl'>
                    {websites.map((website, index) => (
                        website.images.map((image, imgIndex) => (
                            <img 
                                key={`${index}-${imgIndex}`} 
                                src={`https://btobjob.urbancodez.com/admin/backend/${image}`} 
                                alt={website.title} 
                                className='w-full object-contain h-[290px]' 
                            />
                        ))
                    ))}
                </div>
            </div>
        </>
    );
};

export default BuyAWebsite2;