import React, { useState } from 'react';
import './user.css';
import LOGO from '../../assests/b2bjob logo 1.png';
import { useNavigate } from 'react-router-dom';
import Tick from '../../assests/tick - Copy.png';
import api from '../../services/api';

function IndividualSignup() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    terms: false,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await api.signup(formData);
      if (result.success) {
        navigate('/login');
      } else if (result.errors) {
        setErrors(result.errors);
      } else {
        setErrors({ general: 'An unknown error occurred' });
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ general: 'An error occurred while signing up. Please try again.' });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen shadow-2xl">
      <div className="w-96 p-2 rounded-3xl shadow-lg bg-[#E1F5C4]">
        <div className="text-center">
          <img src={LOGO} alt="Logo" className="h-20 mx-auto" />
        </div>
        <div>
          <h4 className="text-center text-[#75C00A] font-extrabold text-xl">Créer un compte</h4>
          <p className="text-center text-[#75C00A] text-sm">Nous sommes heureux de vous voir</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-4">
            <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="name">Nom et prénom</label>
            <input
              className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
              type="text"
              id="name"
              placeholder="Entrez votre nom complet"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
          </div>
          <div className="mt-4">
            <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="email">E-mail</label>
            <input
              className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
              type="email"
              id="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
          </div>
          <div className="mt-4">
            <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="phone">Numéro de téléphone</label>
            <input
              className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
              type="text"
              id="phone"
              placeholder="Entrez votre numéro"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
          </div>
          <div className="mt-4">
            <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="password">Créer un mot de passe</label>
            <input
              className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
              type="password"
              id="password"
              placeholder="entrer un nouveau mot de passe"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}
          </div>
          <div className="mt-4">
            <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="confirmPassword">Confirmez le mot de passe</label>
            <input
              className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
              type="password"
              id="confirmPassword"
              placeholder="Retype your new password"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            {errors.confirmPassword && <p className="text-red-500 text-xs">{errors.confirmPassword}</p>}
          </div>
          <div className="flex items-center mt-4">
            <input type="checkbox" id="terms" className="form-checkbox" checked={formData.terms} onChange={handleChange} />
            <label htmlFor="terms" className="ml-2 text-xs text-[#75C00A] font-semibold flex items-center">
              <img src={Tick} width={12} alt="Tick" className="mr-1" />J'accepte toutes les déclarations incluses dans les conditions d'utilisation
            </label>
            {errors.terms && <p className="text-red-500 text-xs">{errors.terms}</p>}
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full py-2.5 rounded-full bg-gradient-to-r from-[#75C00A] to-[#375A05] text-white font-semibold"
            >
              S'inscrire
            </button>
          </div>
          {errors.general && <p className="text-red-500 text-xs mt-4">{errors.general}</p>}
        </form>
        <div className="mt-4 text-center">
          <p className="text-xs text-[#75C00A] font-normal">or</p>
          <p className="text-xs text-[#65A406] font-normal mt-1">Vous avez déjà un compte ?</p>
          <button
            className="w-full py-2.5 rounded-full border-2 border-[#75C00A] text-[#65A406] font-semibold mt-2"
            onClick={() => navigate('/login')}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
}

export default IndividualSignup;
