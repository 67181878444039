import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'; // Ensure the path is correct

const UpdatePicturePage = () => {
    const [profileImage, setProfileImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [coverImagePreview, setCoverImagePreview] = useState(null);
    const navigate = useNavigate();

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setProfileImagePreview(reader.result);
            reader.readAsDataURL(file);
        } else {
            setProfileImagePreview(null);
        }
    };

    const handleCoverImageChange = (e) => {
        const file = e.target.files[0];
        setCoverImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setCoverImagePreview(reader.result);
            reader.readAsDataURL(file);
        } else {
            setCoverImagePreview(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.updateImages(profileImage, coverImage);

            if (response.success) {
                alert('Images updated successfully');
                navigate(-1); // Redirect to profile page or wherever appropriate
            } else {
                alert('Failed to update images');
            }
        } catch (error) {
            console.error('Error uploading images:', error);
            alert('An error occurred');
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-6">Update Pictures</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex flex-col items-center">
                    <label className="text-lg font-medium mb-2">Profile Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleProfileImageChange}
                        className="mb-4"
                    />
                    {profileImagePreview && (
                        <img
                            src={profileImagePreview}
                            alt="Profile Preview"
                            className="w-32 h-32 object-cover rounded-full border border-gray-300"
                        />
                    )}
                </div>
                <div className="flex flex-col items-center">
                    <label className="text-lg font-medium mb-2">Cover Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleCoverImageChange}
                        className="mb-4"
                    />
                    {coverImagePreview && (
                        <img
                            src={coverImagePreview}
                            alt="Cover Preview"
                            className="w-full h-40 object-cover rounded-md border border-gray-300"
                        />
                    )}
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
                >
                    Update Images
                </button>
            </form>
        </div>
    );
};

export default UpdatePicturePage;
