import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import Star from "../../assests/Star.png";
import LOGO2 from "../../assests/b2bjob logo 1.png";
import axios from "axios";

const BusinessCard = () => {
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [avgRating, setAvgRating] = useState(0);
  const [images, setImages] = useState([null, null, null]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const cardRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);

  const NavigateToBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await api.fetchProfile();
        setProfileData(response);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get("https://btobjob.urbancodez.com/get_photos.php", {
          withCredentials: true,
        });
        if (Array.isArray(response.data)) {
          setPhotos(response.data.slice(0, 3));
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching photos:", error);
        setError("Error fetching photos");
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get("https://btobjob.urbancodez.com/get-user-reviews.php", {
          withCredentials: true,
        });
        const data = response.data;

        const avgRating =
          data.avg_rating !== null && data.avg_rating !== undefined
            ? parseFloat(data.avg_rating)
            : 0;

        setAvgRating(avgRating);
      } catch (error) {
        console.error("Error fetching ratings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, []);

  const handleDownload = () => {
    if (cardRef.current) {
      html2canvas(cardRef.current, {
        useCORS: true,
        allowTaint: true,
        logging: true,
      })
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = `${profileData.Name}_Business_Card.png`;
          link.click();
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    }
  };

  const handlePrint = () => {
    window.print(); // Trigger the print dialog
  };

  const handleImageChange = (index, e) => {
    const newImages = [...images];
    newImages[index] = URL.createObjectURL(e.target.files[0]);
    setImages(newImages);
  };

  const renderStars = () => {
    const fullStars = Math.floor(avgRating);
    const halfStar = avgRating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <img key={index} src={Star} alt="Star" className="w-3 h-3" />
          ))}
        {halfStar ? <img src={Star} alt="Half Star" className="w-3 h-3 opacity-50" /> : null}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <img key={index} src={Star} alt="Empty Star" className="w-3 h-3 opacity-20" />
          ))}
      </>
    );
  };

  if (isLoading || loading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Profile data not found.</div>;
  }

  const profileImagePath = `https://btobjob.urbancodez.com/profile/${profileData.ProfilePicture || "default-profile.jpg"}`;
  const profileUrl = `https://btobjob.urbancodez.com/profile/specificUser?professional_id=${profileData.ID}`;

  return (
    <div className="md:mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-12 m-2 mx-2">
      <div className="flex flex-col">
        {/* Navbar Section */}
        <div className="p-6 border-b-2 flex flex-col print:hidden">
          <div className="flex items-center">
            <FaArrowLeft onClick={NavigateToBack} className="h-6 w-6 pr-2 cursor-pointer" />
            <p className="text-xl font-semibold">Mes Cartes de visite</p>
          </div>
        </div>

        {/* Business Card Section */}
        <div className="flex flex-col items-center justify-center my-8 relative print:hidden">
          <p className="absolute -top-7 left-4 font-semibold">Aperçu</p>
          <div className="rounded-lg shadow-lg p-6 max-w-[260px] sm:max-w-sm relative bg-cover bg-center" ref={cardRef}>
            {/* Centered Logo */}
            <div className="flex justify-center items-center">
              <img src={LOGO2} alt="Logo" className="w-20 object-cover h-6 mt-2" />
            </div>
            <div className="flex justify-between items-center relative z-10">
              <div>
                <h2 className="text-xs font-bold sm:text-sm">{profileData.Name}</h2>
                <div className="flex items-center mt-2">{renderStars()}</div>
              </div>
              <img
                src={profileImagePreview || profileImagePath}
                alt="Profile"
                className="w-10 h-10 sm:h-16 sm:w-16 rounded-full"
              />
            </div>

            <div className="mt-4 relative z-10">
              <div className="flex items-start">
                <div>
                  <p className="text-gray-700 text-xs sm:text-sm">{profileData.Phone}</p>
                  <p className="text-gray-700 text-[8px] sm:text-[12px]">
                    {profileData.Address} {profileData.city}, {profileData.state} {profileData.zip}
                  </p>
                  <a href={profileUrl} className="text-blue-500 text-[8px] sm:text-sm">
                    {profileUrl}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <button
              onClick={handleDownload}
              className="bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold"
            >
              Download
            </button>
          </div>
        </div>

        {/* Dynamic Flyer Section Using Profile Data */}
        <div className="flex flex-col items-center justify-center my-8 relative print:show">
          <p className="absolute -top-7 left-4 font-semibold">Aperçu</p>

          {/* Flyer Card Section */}
          <div className="w-full bg-gray-100 p-8 rounded-lg text-center">
            <h1 className="text-2xl font-bold text-gray-800">{profileData.Name || "Beneddif Services"}</h1>
            <p className="text-lg text-gray-600">{profileData.Phone || "06 18 29 60 34"}</p>

            {/* Images Section */}
            <div className="mt-6 flex justify-center space-x-2">
              {photos.map((photo, index) => (
                <div key={index}>
                  <input
                    type="file"
                    id={`file-input-${index}`}
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(index, e)}
                  />
                  <label htmlFor={`file-input-${index}`}>
                    <img
                      src={`https://btobjob.urbancodez.com/${photo.photo_url}`}
                      alt={`User Photo ${index + 1}`}
                      className="w-16 h-16 object-cover border"
                    />
                  </label>
                </div>
              ))}
            </div>

            {/* Description Paragraph */}
            <p className="mt-4 text-gray-700 text-sm">
              {profileData.bio || "BENEDDIF SERVICES vous propose une prestation globale clés en main, de l'étude du projet à l'exécution des travaux jusqu'aux..."}
            </p>

            {/* Flex Section for Contact and QR Code */}
            <div className="flex justify-between mt-6">
              {/* Left Text Section */}
              <div className="text-left">
                <p className="text-gray-800 font-bold">{profileData.Name || "Beneddif Services"}</p>
                <p className="text-gray-600">{profileData.Phone || "06 18 29 60 34"}</p>
                <p className="text-gray-600">{profileData.Address || "Couteron - Couteron"}</p>
              </div>

              {/* QR Code Section */}
              <div className="text-left">
                <p className="text-gray-800 font-bold">Référencé sur</p>
                <QRCode
                  value={profileUrl}
                  size={60}
                  fgColor="#004225"
                />
                <p className="text-gray-600 text-sm">
                  Scannez ce QR Code pour :
                  <br />
                  - Voir les avis laissés par mes clients
                  <br />
                  - Voir mes réalisations
                  <br />- Me contacter
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Print Button for Flyer */}
        <div className="mt-10 flex justify-center print:hidden">
          <button onClick={handlePrint} className="bg-gray-300 py-2 px-4 rounded-full text-gray-600 font-semibold">
            Imprimer
          </button>
        </div>

        {/* Footer Section */}
        <div className="p-6 border-t-2 flex flex-col print:hidden">
          {/* Footer content here */}
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
