import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaHeart, FaRegHeart } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://btobjob.urbancodez.com'; // Replace with your actual API URL

const Ads = () => {
  const [ads, setAds] = useState([]); // Initialize ads state
  const [likedAds, setLikedAds] = useState([]); // For handling liked ads state
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [totalAds, setTotalAds] = useState(0); // Total ads count for pagination
  const adsPerPage = 10; // Number of ads per page
  const [searchQuery, setSearchQuery] = useState(''); // Normal search query
  const [locationQuery, setLocationQuery] = useState(''); // Proximity search query by city or address
  const navigate = useNavigate();

  // Fetch ads data from the API
  useEffect(() => {
    const fetchAllAds = async (page = 1, adsPerPage = 10) => {
      try {
        console.log('Fetching ads with parameters:', { page, adsPerPage }); // Log request parameters
        const response = await axios.get(`${API_URL}/fetch-all-ads.php`, {
          params: { page, adsPerPage },
          withCredentials: true,
        });

        console.log('API Response:', response.data); // Log the entire response for debugging

        // Ensure the response contains ads and totalAds fields
        if (response.data && Array.isArray(response.data.ads)) {
          setAds(response.data.ads);
          setTotalAds(response.data.totalAds || 0); // Fallback to 0 if undefined
        } else {
          console.error('Invalid response structure:', response.data);
          setAds([]); // Reset ads if the response is invalid
          setTotalAds(0);
        }
      } catch (error) {
        console.error('Error fetching ads:', error);
        setAds([]); // Reset ads in case of error
        setTotalAds(0);
      }
    };

    fetchAllAds(currentPage, adsPerPage);
  }, [currentPage]); // Re-run effect when currentPage changes

  // Handle ad like toggle
  const handleLikeToggle = (adId) => {
    if (likedAds.includes(adId)) {
      setLikedAds(likedAds.filter((id) => id !== adId));
    } else {
      setLikedAds([...likedAds, adId]);
    }
  };

  // Handle click to navigate to ad details page
  const handleAdClick = (adId) => {
    navigate(`/profile/AdsDetails?ad_id=${adId}`);
  };

  // Handle page change for pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handle normal search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to the first page on new search
  };

  // Handle city and address search input change
  const handleLocationSearchChange = (e) => {
    setLocationQuery(e.target.value.toLowerCase());
    setCurrentPage(1); // Reset to the first page on new search
  };

  const getFilteredAds = () => {
    return ads.filter((ad) => {
      // Ensure fields are properly defined and are strings
      const title = ad.title?.toLowerCase().trim() || '';
      const city = ad.city?.toLowerCase().trim() || '';
      const address = ad.Address?.toLowerCase().trim() || '';  // Ensure 'Address' is correctly referenced
      console.log(`Ad Title: ${title}, Search Query: ${searchQuery}`);

      // Filter by title and description based on searchQuery
      const matchesSearchQuery = !searchQuery || title.includes(searchQuery);
  
      // Filter by city or address based on locationQuery
      const matchesLocationQuery = !locationQuery || city.includes(locationQuery) || address.includes(locationQuery);
  
      // Return ads that match either search condition
      return matchesSearchQuery && matchesLocationQuery;
    });
  };

  const filteredAds = getFilteredAds();

  return (
    <div className="max-w-6xl mx-auto p-4">
      {/* Search Bars */}
      <div className="flex flex-col md:flex-row gap-4 p-4">
        {/* Normal Search Input */}
        <input
          type="text"
          className="w-full text-xs px-2 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Rechercher par titre ou description"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {/* City and Address Search Input */}
        <input
          type="text"
          className="w-full text-xs px-2 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Rechercher par ville ou adresse"
          value={locationQuery}
          onChange={handleLocationSearchChange}
        />
      </div>

      {/* Ads List */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {filteredAds.length > 0 ? (
          filteredAds.map((ad) => (
            <div 
              key={ad.id} 
              className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow duration-300 cursor-pointer"
              onClick={() => handleAdClick(ad.id)} // Attach click handler here
            >
              {ad.image && (
                <img
                  src={ad.image}
                  alt={ad.title}
                  className="w-full h-52 object-cover rounded-md mb-4"
                />
              )}
              <h2 className="text-lg font-bold mb-2">{ad.title}</h2>
              <p className="text-sm text-gray-700">{ad.city}</p>
              <button
                onClick={(e) => { e.stopPropagation(); handleLikeToggle(ad.id); }} // Prevent click event propagation
                className="absolute top-2 right-2 text-red-500"
              >
                {likedAds.includes(ad.id) ? <FaHeart /> : <FaRegHeart />}
              </button>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Aucune annonce trouvée.</p>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="mt-6 flex justify-center">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className={`px-4 py-2 rounded-md mr-2 ${
            currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          Précédent
        </button>
        <button
          disabled={currentPage === Math.ceil(totalAds / adsPerPage)}
          onClick={() => handlePageChange(currentPage + 1)}
          className={`px-4 py-2 rounded-md ${
            currentPage === Math.ceil(totalAds / adsPerPage) ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default Ads;
