import React from 'react'
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import btnArrow from '../assests/inputbtnarrow.png'
import HomeRoom from '../assests/homeroom.png'
import Slider3 from '../assests/slider3.png'
import Star from '../assests/Star.png'
import Slider4 from '../assests/homerankingimageman.png'
import Slider5 from '../assests/slider5.png'
import SelfEmployed from '../assests/selfemployed.png'
import Company from '../assests/company.png'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bg1 from "../assests/Rectangle 30.png"
import bg2 from "../assests/Rectangle 18.png"
const Professional = () => {
  const navigate = useNavigate();
  const NavigateToProff = () => {
    navigate("/professionaluser");
  };
  const slidersContainerRef = useRef(null);

  const handleScroll = () => {
    if (slidersContainerRef.current) {
      slidersContainerRef.current.scrollBy({
        left: slidersContainerRef.current.clientWidth,
        behavior: 'smooth'
      });
    }
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1
      //   }
      // }
    ]
  };


  return (
    <div className="py-12 relative bg-[#FFF]">
      {/* <div className="text-center mb-8">
        <h1 className="text-xl sm:text-4xl font-semibold mb-2">
          Trouver le professionnel
        </h1>
        <p className="text-sm font-medium">Trouvez le service adapté à chaque niveau de prix.</p>
      </div> */}
      <div className='w-full mt-10 mb-10'>
        {/* <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 px-4 ">
          {[
            { city: 'Paris', name: 'Dorothy James', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider4, badge: SelfEmployed },
            { city: 'Newark City', name: 'Tek Renovation', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider5, badge: Company },
            { city: 'Paris', name: 'Ella James', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider3, badge: SelfEmployed }
          ].map((slider, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between w-[270px] sm:w-[300px]">
              <div className="flex items-center mb-4">
                <img className="w-8 h-8 mr-4" src={slider.smallPic} alt={`${slider.city} picture`} />
                <div>
                  <span className="text-gray-500 text-xs">{slider.city} <img className="inline-block ml-1" src={slider.badge} width={20} alt={`${slider.city} badge`} /></span>
                  <span className="block font-semibold text-sm">{slider.name}</span>
                </div>
              </div>
              <p className="font-semibold text-xs mb-2">{slider.job} <span className="text-xs font-normal text-gray-400"><img className="inline-block mr-1" src={Star} width={10} alt="star" />{slider.rating}</span></p>
              <div className="mb-4 flex justify-center">
                <img className="w-full object-contain" src={slider.image} alt={`${slider.city} room`} />
              </div>
              <p className="text-center text-xs mb-4">
                Dernières candidatures Trouvez et embauchez
                <br />
                facilement des professionnels qualifiés pour le
                <br />
                nettoyage de votre chambre.
              </p>
              <p className="text-xs font-medium text-gray-500">il y a 3 heures</p>
            </div>
          ))}
        </div> */}
        <Slider {...settings}>
          {[
            { city: 'Paris', name: 'Dorothy James', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider4, badge: SelfEmployed },
            { city: 'Newark City', name: 'Tek Renovation', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider5, badge: Company },
            { city: 'Paris', name: 'Ella James', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider3, badge: SelfEmployed },
            { city: 'Paris', name: 'Ella James', job: 'NETTOYAGE CHAMBRE', rating: '4.7/5', image: HomeRoom, smallPic: Slider3, badge: SelfEmployed }
          ].map((slider, index) => (
            <div className='w-full lg:mx-24'>
              <div key={index} className="bg-white mt-8 rounded-xl border-2 border-lime-600 shadow-lg p-4 flex flex-col justify-between w-full sm:w-[300px]">
                <div className="flex items-center mb-4">
                  <img className="w-8 h-8 mr-4" src={slider.smallPic} alt={`${slider.city} picture`} />
                  <div>
                    <span className="text-gray-500 text-xs">{slider.city} <img className="inline-block ml-1" src={slider.badge} width={20} alt={`${slider.city} badge`} /></span>
                    <span className="block font-semibold text-sm">{slider.name}</span>
                  </div>
                </div>
                <p className="font-semibold text-xs mb-2">{slider.job} <span className="text-xs font-normal text-gray-400"><img className="inline-block mr-1" src={Star} width={10} alt="star" />{slider.rating}</span></p>
                <div className="mb-4 flex justify-center">
                  <img className="w-full object-contain" src={slider.image} alt={`${slider.city} room`} />
                </div>
                <p className="text-start text-xs mb-4">
                  Dernières candidatures Trouvez et embauchez
                  <br />
                  facilement des professionnels qualifiés pour le
                  <br />
                  nettoyage de votre chambre.
                </p>
                <p className="text-xs font-medium text-gray-500">il y a 3 heures</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>


      <div className="flex justify-center mt-8">
        {/* <button className="bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center" onClick={NavigateToProff}>
          se connecter
          <img className="ml-2" src={btnArrow} width={17} alt="arrow icon" />
        </button> */}
      </div>

      {/* <img className='absolute w-full lg:h-[30%] h-[30%] -bottom-14 -z-10' src={bg1}/> */}
      {/* <img className='absolute w-full h-[30%] -top-10 -z-10' src={bg2}/> */}
    </div>
  );
}

export default Professional;
