import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Star from "../../assests/Star.png";
import { MdCancel } from "react-icons/md";
import { IoFilter } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import PrivateRequestForm from './PrivateRequestForm';

const Offerpage = () => {
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [filter, setFilter] = useState(null);
    const [offers, setOffers] = useState([]);
    const [nameQuery, setNameQuery] = useState('');
    const [locationQuery, setLocationQuery] = useState('');

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const data = await api.fetchOffers();
                console.log('Fetched offers:', data);
                setOffers(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching offers:', error);
            }
        };

        fetchOffers();
    }, []);

    const toggleDropdownForFilter = () => {
        setIsOpenFilter(prevState => !prevState);
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setIsOpenFilter(false);
    };

    const handleNameSearchChange = (e) => {
        setNameQuery(e.target.value);
    };

    const handleLocationSearchChange = (e) => {
        setLocationQuery(e.target.value);
    };

    const navigate = useNavigate();

    const handleNavigateToAllThemes = (category) => {
        navigate(`/profile/allthemes?category=${category}`);
    };

    const getFilteredOffers = () => {
        const lowercasedNameQuery = nameQuery.toLowerCase();
        const lowercasedLocationQuery = locationQuery.toLowerCase();
        const filteredOffers = offers.filter(offer =>
            (offer.category === filter || !filter) &&
            (
                (offer.professional_name?.toLowerCase().includes(lowercasedNameQuery) || !lowercasedNameQuery) &&
                (offer.professional_city?.toLowerCase().includes(lowercasedLocationQuery) || !lowercasedLocationQuery)
            )
        );

        console.log('Filtered offers:', filteredOffers);

        if (!Array.isArray(filteredOffers)) {
            return {};
        }

        const offersPerCategory = {};
        filteredOffers.forEach(offer => {
            if (!offersPerCategory[offer.category]) {
                offersPerCategory[offer.category] = [];
            }
            if (offersPerCategory[offer.category].length < 2) {
                offersPerCategory[offer.category].push(offer);
            }
        });

        return offersPerCategory;
    };

    const filteredOffers = getFilteredOffers();

    return (
        <div className=''>
            <div className="md:mx-auto gap-2 m-2 max-w-6xl md:mt-12 rounded-lg flex flex-col md:flex-row">
                <div className='flex flex-col bg-white rounded-xl'>
                    <div className='sm:p-6 p-3 border-b-2'>
                        <p className="font-bold text-2xl">ENGAGER UN PRO</p>
                    </div>
                    <div className="mx-auto sm:p-6 p-4 flex flex-col md:flex-row items-center justify-between gap-2">
                        <div className="relative w-[300px] sm:w-[500px] flex flex-col md:flex-row gap-4">
                            {/* Input field for name */}
                            <input
                                type="text"
                                className="w-full text-xs px-1 md:px-6 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Rechercher par nom"
                                value={nameQuery}
                                onChange={handleNameSearchChange}
                            />
                            {/* Input field for location */}
                            <input
                                type="text"
                                className="w-full text-xs px-1 md:px-6 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Rechercher par lieu"
                                value={locationQuery}
                                onChange={handleLocationSearchChange}
                            />
                        </div>
                    </div>
                    {Object.entries(filteredOffers).map(([category, categoryOffers], index) => (
                        <div key={index} className='flex flex-col gap-2 sm:p-6 p-3 border-t-2'>
                            <div className="flex justify-between items-center mt-6 mb-3">
                                <div>
                                    <h2 className="text-xl font-medium">Service de {category}</h2>
                                    {categoryOffers && categoryOffers.length > 0 && (
                                        <p className="text-sm text-gray-500">Créé le {new Date(categoryOffers[0].created_at).toLocaleDateString()}</p>
                                    )}
                                </div>
                                <button
                                    onClick={() => navigate(`/profile/allthemes?category=${category}`)}
                                    className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium text-sm sm:text-base rounded-xl inline-flex items-center"
                                >
                                    <span>Voir tout</span>
                                </button>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                                {categoryOffers && categoryOffers.slice(0, 2).map((offer, index) => (
                                    <ProfileCard key={index} offer={offer} />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const ProfileCard = ({ offer }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const imageUrl = `https://btobjob.urbancodez.com/${offer.image}`;

    const handleNavigateToProfile = () => {
        navigate(`/profile/specificUser?professional_id=${offer.professional_id}`);
    };

    const handleOpenForm = () => {
        setOpenForm(true);
    };

    return (
        <div className="bg-white rounded-xl shadow-2xl p-3 sm:p-4">
            <div className="flex items-center">
                <div className='flex justify-between items-start w-full'>
                    <div className='flex items-center'>
                        <img src={offer.professional_Picture} alt="Offer Image" className="w-12 h-12 rounded-full mr-4" />
                        <div>
                            <h2 className="text-lg font-bold text-gray-800">{offer.professional_name}</h2>
                            <span className="text-sm text-gray-500">{offer.professional_city}</span>
                            <span className="text-sm text-gray-500">{offer.location}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center'>
                <div className="flex items-center mt-2">
                    <img src={Star} className="h-3 sm:h-4 mt-1" />
                    <p className='text-xs ml-2'>{offer.average_rating ? parseFloat(offer.average_rating).toFixed(1) : 'N/A'}</p>
                </div>
            </div>
            <p className="text-sm mt-2 text-gray-700 h-14 overflow-hidden">{offer.description}</p>
            <img
                src={imageUrl}
                alt="Offer Image"
                className="w-full mt-4 mb-6 rounded-lg"
                onClick={() => setShowPopup(true)}
            />
            <div className="flex justify-between">
                <button
                    onClick={handleNavigateToProfile}
                    className="bg-[#65A406] text-white text-gray-800 font-medium text-sm sm:text-base py-2 px-4 rounded-xl inline-flex items-center"
                >
                    Voir le profil
                </button>
                <button
                    onClick={handleOpenForm}
                    className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium text-sm sm:text-base py-2 px-4 rounded-xl inline-flex items-center"
                >
                    Demande privée
                </button>
            </div>
            {openForm && <PrivateRequestForm boolean={true} professionalId={offer.professional_id} onClose={() => setOpenForm(false)} />}
            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg">
                        <img src={imageUrl} alt="Offer Image" className="w-full h-auto max-w-md" />
                        <button
                            onClick={() => setShowPopup(false)}
                            className="mt-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Offerpage;
