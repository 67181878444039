import React, { useState, useEffect } from 'react'

const Notifications = ({boolean, returnState}) => {
    const [localToggle, setLocalToggle] = useState(boolean);

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        if (localToggle) {
            console.log('Form has been toggled. Fetching recommendations...');
            // Fetch recommendations or perform any other necessary actions
        }
    }, [localToggle]);
    const handleClose = () => {
        setLocalToggle(!localToggle)
        returnState(!localToggle)
    }
    return (

        <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-[9999] bg-black/50 `}>
        <div class="lg:w-2/5 sm:w-3/5 w-11/12 bg-gray-100 rounded-xl mx-auto border p-2 shadow-sm h-[500px] overflow-y-scroll">
            <div class="inline-flex items-center justify-between w-full">
                <h3 class="font-bold text-sm sm:text-2xl text-gray-800">Notifications</h3>
                <button
                onClick={handleClose}
                    class="inline-flex text-xs sm:text-sm bg-white px-2 sm:px-3 py-2 text-lime-600 items-center rounded font-medium
     shadow border focus:outline-none transform active:scale-75 transition-transform duration-700 hover:bg-lime-600
      hover:text-white hover:-translate-y-1 hover:scale-110">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 sm:mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                    Fermer
                </button>
            </div>
            {/* <p class="mt-2 font-medium text-gray-500 text-sm sm:text-base">Aujourd'hui</p>
            <div class="mt-2 px-6 py-4 bg-white rounded-lg shadow w-full">
                <div class="inline-flex items-center justify-between w-full">
                    <div class="inline-flex items-center">
                        <img
                            src="https://cdn-icons-png.flaticon.com/128/763/763812.png"
                            alt="Icône d'entraînement" class="w-6 h-6 mr-3" />
                        <h3 class="font-bold text-base text-gray-800">Entraînement</h3>
                    </div>
                    <p class="text-xs text-gray-500">
                        Il y a 2 min
                    </p>
                </div>
                <p class="mt-1 text-sm">
                    Hé! Vous souvenez-vous d'avoir choisi votre régime d'entraînement ?
                </p>
            </div>
            <div class="mt-2 px-6 py-4 bg-white rounded-lg shadow w-full">
                <div class="inline-flex items-center justify-between w-full">
                    <div class="inline-flex items-center">
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/893/893257.png"
                            alt="Icône de messages" class="w-6 h-6 mr-3" />
                        <h3 class="font-bold text-base text-gray-800">Messages</h3>
                    </div>
                    <p class="text-xs text-gray-500">
                        Il y a 1 heure
                    </p>
                </div>
                <p class="mt-1 text-sm">
                    Vous avez un nouveau message
                </p>
            </div>
            <p class="mt-2 font-medium text-gray-500 text-sm sm:text-base">Hier</p>
            <div class="mt-2 px-6 py-4 bg-white rounded-lg shadow w-full">
                <div class="inline-flex items-center justify-between w-full">
                    <div class="inline-flex items-center">
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/6863/6863272.png"
                            alt="Icône de formulaire" class="w-6 h-6 mr-3" />
                        <h3 class="font-bold text-base text-gray-800">Formulaires</h3>
                    </div>
                    <p class="text-xs text-gray-500">
                        12:47
                    </p>
                </div>
                <p class="mt-1 text-sm">
                    N'oubliez pas de remplir le formulaire COVID-19 avant le prochain rendez-vous demain
                </p>
            </div>
            <div class="mt-2 px-6 py-4 bg-white rounded-lg shadow w-full">
                <div class="inline-flex items-center justify-between w-full">
                    <div class="inline-flex items-center">
                        <img
                            src="https://cdn-icons-png.flaticon.com/128/763/763812.png"
                            alt="Icône d'entraînement" class="w-6 h-6 mr-3" />
                        <h3 class="font-bold text-base text-gray-800">Entraînement</h3>
                    </div>
                    <p class="text-xs text-gray-500">
                        12:43
                    </p>
                </div>
                <p class="mt-1 text-sm">
                    Nous sommes ravis que vous ayez décidé d'utiliser notre système d'entraînement! Maintenant, définissons un ensemble complet de choses
                </p>
            </div> */}
            <button
                class="inline-flex text-sm bg-white justify-center px-4 py-2 mt-12 w-full text-red-500 items-center rounded font-medium
 shadow border focus:outline-none transform active:scale-75 transition-transform duration-700 hover:bg-red-500
  hover:text-white hover:-translate-y-1 hover:scale-110 F">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2 sm:mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Effacer toutes les notifications
            </button>
        </div>
    </div>

    )
}

export default Notifications