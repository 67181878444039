import React, { useState, useEffect } from 'react';
import { MdCancel } from "react-icons/md";
import api from '../../services/api';

function UploadingCv({ boolean, returnState, jobId }) {
    const [files, setFiles] = useState([]);

    const handleFileUpload = (event) => {
        const uploadedFiles = Array.from(event.target.files);
        setFiles([...files, ...uploadedFiles]);
    };

    const removeFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const [localToggle, setLocalToggle] = useState(boolean);

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        if (localToggle) {
            console.log('Form has been toggled. Fetching recommendations...');
            // Fetch recommendations or perform any other necessary actions
        }
    }, [localToggle]);

    const handleClose = () => {
        setLocalToggle(false);
        returnState(false);
    };

    const uploadFiles = async () => {
        try {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('files[]', file);
            });
            formData.append('jobId', jobId); // Include jobId in FormData

            // Send formData to backend API
            const response = await api.uploadFiles(formData);
            console.log('Files uploaded successfully:', response);

            // Reset files state after successful upload
            setFiles([]);
            handleClose(); // Close the upload component
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    return (
        <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-10 bg-black/50`}>
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
                <MdCancel onClick={handleClose} size={20} className='absolute right-2 top-2 cursor-pointer'/>

                <h2 className="text-2xl font-bold mb-6 text-center">Télécharger CV</h2>
                <div className="border-dashed border-2 border-gray-300 p-6 rounded-lg text-center">
                    <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        onChange={handleFileUpload}
                        multiple
                    />
                    <label
                        htmlFor="fileInput"
                        className="cursor-pointer text-[#65A406]"
                    >
                        <span className="block mb-2">Glissez-déposez des fichiers ou <span className="underline">Parcourir</span></span>
                        <span className="block text-sm">Formats supportés : JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</span>
                    </label>
                </div>

                <div className="mt-4">
                    {files.map((file, index) => (
                        <div key={index} className="flex items-center justify-between mb-2 p-2 rounded-lg border">
                            <span>{file.name}</span>
                            <button
                                onClick={() => removeFile(index)}
                                className="text-red-500 cursor-pointer"
                            >
                                🗑️
                            </button>
                        </div>
                    ))}
                </div>

                <button onClick={uploadFiles} className="mt-6 w-full bg-[#65A406] text-white py-2 rounded-lg">SOUMETTRE LE CV</button>
            </div>
        </div>
    );
}

export default UploadingCv;
