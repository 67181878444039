// src/components/ProfilePhotos.jsx
import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

const ProfilePhotos = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Extract professional_id from the URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const professionalId = searchParams.get('professional_id');

    useEffect(() => {
        if (!professionalId) {
            setError("Invalid professional_id");
            setLoading(false);
            return;
        }

        const fetchPhotos = async () => {
            try {
                const response = await axios.get(`https://btobjob.urbancodez.com/get_photos_specific.php?professional_id=${professionalId}`, { withCredentials: true });
                if (Array.isArray(response.data)) {
                    setPhotos(response.data);
                } else {
                    throw new Error('Unexpected response format');
                }
            } catch (error) {
                console.error("Error fetching photos:", error);
                setError("Error fetching photos");
            } finally {
                setLoading(false);
            }
        };

        fetchPhotos();
    }, [professionalId]);

    const NavigateToBack = () => {
        navigate(-1); // Navigates back one step in the history stack
    };

    const navigateToAddPhotos = () => {
        window.location.href = "https://btobjob.urbancodez.com/add-photos.php"; // Redirect to add photos page
    };

    return (
        <div className="mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-16">
            <div className='flex flex-col'>
                <div className='p-6 border-b-2 flex flex-col'>
                    <div onClick={NavigateToBack} className='flex items-center cursor-pointer'>
                        <FaArrowLeft className='h-6 w-6 pr-2' />
                        <p className="text-xl font-semibold">Toutes les photos</p>
                    </div>
                    <p className='text-xs'>Voici quelques photos récentes</p>
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                        {photos.map((photo) => (
                            <div key={photo.id} className="p-4">
                                <div className="bg-white border rounded-lg w-full">
                                    <img src={`https://btobjob.urbancodez.com/${photo.photo_url}`} className='w-full rounded-tl-lg rounded-tr-lg object-contain' alt="User Photo" />
                                    <div className="flex items-center justify-between mx-4 mt-3 mb-2">
                                        {/* Additional content like likes, comments, etc. */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className='p-6'>
                    {/* Uncomment the button for adding photos */}
                    {/* <button 
                        onClick={navigateToAddPhotos}
                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    >
                        Ajouter des Photos
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default ProfilePhotos;
