// import React from 'react';
// import btb from "../assests/b2bUpdated.png"
// const CenteredLogoWithParagraphs = () => {
//   return (
//     <div className="relative flex items-center justify-center min-h-screen px-4">
//       {/* Centered Logo */}
//       <div className="relative">
//         <img 
//           src={btb}
//           alt="Logo" 
//           className="w-16 h-16 sm:w-20 sm:h-20 mx-auto"
//         />

//         {/* Paragraphs with Lines */}
//         {paragraphs.map((paragraph, index) => (
//           <div key={index} className={`absolute flex items-center justify-center ${positions[index]} w-24 sm:w-32`}>
//             <div className="w-px h-8 sm:h-12 bg-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
//             <p className="text-center text-xs sm:text-sm">{paragraph}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const paragraphs = [
//   'Paragraph 1', 'Paragraph 2', 'Paragraph 3',
//   'Paragraph 4', 'Paragraph 5', 'Paragraph 6',
//   'Paragraph 7', 'Paragraph 8', 'Paragraph 9'
// ];

// const positions = [
//   "top-0 left-1/2 transform -translate-x-1/2",
//   "top-10 right-0 transform -translate-x-1/2",
//   "top-20 right-0 transform -translate-x-1/2",
//   "bottom-20 right-0 transform -translate-x-1/2",
//   "bottom-10 right-0 transform -translate-x-1/2",
//   "bottom-0 left-1/2 transform -translate-x-1/2",
//   "bottom-10 left-0 transform -translate-x-1/2",
//   "bottom-20 left-0 transform -translate-x-1/2",
//   "top-10 left-0 transform -translate-x-1/2",
// ];

// export default CenteredLogoWithParagraphs;
import React from 'react';
import btb from "../assests/b2bUpdated.png";
import hero from "../assests/hero.png"
import { Link } from 'react-router-dom';
import vd from "../assests/hero_video.mp4"
const CenteredLogoWithParagraphs = () => {
  return (
    <div className="relative w-full  lg:h-screen flex flex-col justify-start items-center  bg-[#fff]">

      <div className='flex flex-row justify-between items-center w-[80%]  '>
        <button className=" hidden md:flex  professional-navbar">
          <Link className="professional-navbar-link text-sm" to="/professionaluser">Se Connecter Particulier</Link>
        </button>
        <button className=" hidden md:flex  professional-navbar">
          <Link className="professional-navbar-link text-sm" to="/professionaluser">Se Connecter Professionnel</Link>
        </button>
      </div>

      {/* <img src={hero} className='w-full object-contain h-[80%] mb-20 ' /> */}
      <video className='w-[50%]  mb-20 ' autoPlay loop muted>
        <source src={vd} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/*   
      <div className="absolute">
        <img src={btb} alt="Logo" className="w-20 h-20 md:w-[110%] md:h-[110%] object-contain" />
      </div>
      
     
      <div className="absolute w-full h-full flex justify-center items-center">
        <div className="w-3/4 h-3/4 relative">
          <div className="absolute inset-0 animate-spin-slow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-full w-10 h-10 p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-90">
              <p className="w-20 md:w-32 text-base">Paragraph 1</p>
            </div>
            <div className="absolute top-1/4 left-3/4 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-[45deg]">
              <p className="w-20 md:w-32">Paragraph 2</p>
            </div>
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-0">
              <p className="w-20 md:w-32">Paragraph 3</p>
            </div>
            <div className="absolute bottom-1/4 left-3/4 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-[-45deg]">
              <p className="w-20 md:w-32">Paragraph 4</p>
            </div>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-90">
              <p className="w-20 md:w-32">Paragraph 5</p>
            </div>
            <div className="absolute bottom-1/4 left-1/4 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-[45deg]">
              <p className="w-20 md:w-32">Paragraph 6</p>
            </div>
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-0">
              <p className="w-20 md:w-32">Paragraph 7</p>
            </div>
            <div className="absolute top-1/4 left-1/4 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-[-45deg]">
              <p className="w-20 md:w-32">Paragraph 8</p>
            </div>
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 text-center bg-[#e7f9cc] rounded-3xl p-3 before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-t before:border-black before:border-dotted before:w-0 before:h-20 before:rotate-90">
              <p className="w-20 md:w-32">Paragraph 9</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CenteredLogoWithParagraphs;
