import React, { useState, useEffect } from "react";
import userProfile from "../assests/people2.png";
import { RiRadioButtonLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import api from '../services/api';

const Conversation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [contact, setContact] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageModal, setImageModal] = useState({ isOpen: false, imageUrl: "" });
  const [sessionId, setSessionId] = useState(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const receiverId = queryParams.get('id');

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest("#menuButton") &&
      !event.target.closest("#menuDropdown")
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        console.log('Session response:', response);
        if (response && response.user && response.user.id) {
          setSessionId(response.user.id);
        } else {
          console.error('Failed to fetch session: No user data in response');
        }
      } catch (error) {
        console.error('Failed to fetch session:', error.message);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (receiverId) {
      const fetchContactAndMessages = async () => {
        try {
          const contactResponse = await api.fetchContactById(receiverId);
          console.log('Contact response:', contactResponse);
          if (contactResponse.success) {
            setContact(contactResponse.contact);
          } else {
            console.error('Failed to fetch contact:', contactResponse.message);
          }

          const messagesResponse = await api.fetchConversation(receiverId);
          console.log('Messages response:', messagesResponse);
          if (messagesResponse.success) {
            setMessages(messagesResponse.messages);
          } else {
            console.error('Failed to fetch messages:', messagesResponse.message);
          }
        } catch (error) {
          console.error('Error fetching contact or messages:', error);
        }
      };
      fetchContactAndMessages();
    }
  }, [receiverId]);

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageClick = (imageUrl) => {
    setImageModal({ isOpen: true, imageUrl });
  };

  const closeImageModal = () => {
    setImageModal({ isOpen: false, imageUrl: "" });
  };

  const handleSendMessage = async () => {
    if (!sessionId) {
      console.error("Session ID is missing");
      return;
    }

    if (newMessage.trim() === "" && !selectedImage) {
      console.error("Message or image is missing");
      return;
    }

    // Temporary message object with loading state
    const tempMessage = {
      id: Date.now(), // Temporary ID
      message: newMessage,
      image_url: isUploading ? "loading" : null, // Placeholder for loading icon
      is_sent_by_current_user: true,
    };

    // Update messages state immediately
    setMessages((prevMessages) => [...prevMessages, tempMessage]);
    setIsUploading(true); // Start loading state

    const formData = new FormData();
    formData.append('receiver_id', receiverId);
    formData.append('message', newMessage);
    formData.append('session_id', sessionId);

    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    try {
      const response = await api.sendMessage(formData);
      setIsUploading(false); // End loading state

      if (response.success) {
        // Update the message with the actual image URL
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessage.id
              ? { ...msg, image_url: response.image_url } // Update with the actual image URL
              : msg
          )
        );
        setNewMessage("");
        setSelectedImage(null); // Clear the selected image

        // Refresh the messages state after sending the message
        const updatedMessagesResponse = await api.fetchConversation(receiverId);
        console.log('Updated messages response:', updatedMessagesResponse);
        if (updatedMessagesResponse.success) {
          setMessages(updatedMessagesResponse.messages);
        } else {
          console.error('Failed to fetch updated messages:', updatedMessagesResponse.message);
        }
      } else {
        console.error('Failed to send message:', response.message || 'Invalid response format');
      }
    } catch (error) {
      setIsUploading(false); // End loading state in case of error
      console.error('Error sending message:', error.message);
    }
  };

  return (
    <div className="flex flex-col max-w-6xl md:mt-10 md:mx-auto m-2 h-[700px] rounded-xl">
      <div className="flex-1">
        <header className="bg-white py-6 px-1 text-gray-700 flex justify-between items-center border-b-2">
          <div className="flex items-center">
            <div className="relative">
              <img
                className="rounded-full w-16 h-16"
                src={contact?.ProfilePicture || userProfile}
                alt="profile"
              />
              <RiRadioButtonLine color="green" className="absolute right-1 top-9" />
            </div>
            <div>
              <h1 className="text-lg font-normal">{contact?.Name || 'Loading...'}</h1>
            </div>
          </div>
        </header>

        <div className="flex flex-col bg-white h-[700px]">
          <div className="overflow-y-auto p-4 pb-36 flex-1">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`flex mb-4 ${msg.is_sent_by_current_user ? 'justify-end' : 'justify-start'}`}
              >
                {msg.is_sent_by_current_user && (
                  <div className={`flex items-center bg-lime-500 text-white rounded-lg p-3 max-w-xs gap-3`}>
                    <p className="text-xs sm:text-base">{msg.message}</p>
                    {msg.image_url === "loading" ? (
                      <div className="w-16 h-16 flex items-center justify-center">
                        <div className="loader">Loading...</div> {/* Replace this with your loading icon */}
                      </div>
                    ) : msg.image_url ? (
                      <img
                        src={msg.image_url}
                        alt="Attachment"
                        className="w-16 h-16 object-cover rounded-lg cursor-pointer"
                        onClick={() => handleImageClick(msg.image_url)}
                      />
                    ) : null}
                    <div className="w-9 h-9 rounded-full flex items-center justify-center ml-2">
                      <img
                        src="https://placehold.co/200x/b7a8ff/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato"
                        alt="My Avatar"
                        className="w-8 h-8 object-cover rounded-full"
                      />
                    </div>
                  </div>
                )}
                {!msg.is_sent_by_current_user && (
                  <div className="flex items-center mr-2">
                    <div className="w-9 h-9 rounded-full flex items-center justify-center">
                      <img
                        src="https://placehold.co/200x/ffa8e4/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato"
                        alt="User Avatar"
                        className="w-8 h-8 object-cover rounded-full"
                      />
                    </div>
                    <div className="flex items-center bg-gray-200 text-black rounded-lg p-3 max-w-xs gap-3">
                      <p className="text-xs sm:text-base">{msg.message}</p>
                      {msg.image_url && (
                        <img
                          src={msg.image_url}
                          alt="Attachment"
                          className="w-16 h-16 object-cover rounded-lg cursor-pointer"
                          onClick={() => handleImageClick(msg.image_url)}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <footer className="bg-white border-t border-gray-300 p-4">
            <div className="flex flex-col sm:flex-row items-center sm:space-x-4 space-y-4 sm:space-y-0">
              <input
                type="text"
                placeholder="Tapez votre message..."
                value={newMessage}
                onChange={handleNewMessageChange}
                className="flex-1 border-2 border-gray-300 rounded-lg py-2 px-4"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="sm:w-auto w-full"
              />
              <button
                onClick={handleSendMessage}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg sm:w-auto w-full"
              >
                Send
              </button>
            </div>
          </footer>

        </div>
      </div>

      {/* Image Modal */}
      {imageModal.isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
          onClick={closeImageModal}
        >
          <img
            src={imageModal.imageUrl}
            alt="Full-size Image"
            className="max-w-full max-h-full"
          />
        </div>
      )}
    </div>
  );
};

export default Conversation;
