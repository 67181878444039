import React from 'react'
import btnArrow from '../assests/inputbtnarrow.png'
import HomeProfessional1 from '../assests/professional1.png'
import HomeProfessional2 from '../assests/professional2.png'
import HomeProfessional3 from '../assests/professional3.png'
import { useNavigate } from "react-router-dom";
import btb from "../assests/b2bUpdated.png"
const Contract = () => {
  const navigate = useNavigate();
  const NavigateToAllservices = () => {
    navigate("/profile/services");
};
  return (
    <div className='mb-4 lg:mt-20 mt-16 bg-[#FFF]'>
      <div className='flex gap-x-3 items-center justify-center'>
        
        <img src={btb} className='w-[30%] lg:w-[15%]'/>
      

      </div>
        
        <div class="all-professional-content">
          <div className="professional-content">
            <img className="professional-image" src={HomeProfessional1} width={200} />
            <p className="professional-para">
              1. Soyez alerté en temps réel de
              <br />
              demandes autour de vous
            </p>
          </div>

          <div className="professional-content">
            <img className="professional-image" src={HomeProfessional2} width={200} />
            <p className="professional-para">
              2 Répondre aux demandes et aux services
            </p>
          </div>

          <div className="professional-content">
            <img className="professional-image" src={HomeProfessional3} width={220} />
            <p className="professional-para">
              3. Soyez payé et évalué
            </p>
          </div>
        </div>
        <div className="flex justify-center">
        {/* <button onClick={NavigateToAllservices} className="bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center">
        Proposez vos services
          <img className="ml-2" src={btnArrow} width={17} alt="arrow icon" />
        </button> */}
      </div>


    </div>
  )
}

export default Contract
