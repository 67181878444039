import NavbarSection from "../components/Navbar";
import '../pages/pages.css'
// import HomeImage from '../assests/homeimage.jpeg'




import Star from '../assests/Star.png'

import { useState } from "react";

import btnArrow from '../assests/inputbtnarrow.png'

import RedRanking from '../assests/redranking.png'

import HomeRoom from '../assests/homeroom.png'

import React, { useRef } from 'react';
import HomeProfessional1 from '../assests/professional1.png'
import HomeProfessional2 from '../assests/professional2.png'
import HomeProfessional3 from '../assests/professional3.png'
import ProLogo from '../assests/prologo.png'
import People4 from '../assests/4people.png'
import World from '../assests/World.png'
import Screw from '../assests/screw.png'
import Alarm from '../assests/Alarm.png'
import Search from '../assests/search.png'
import IdCard from '../assests/id card.png'
import LessColourStar from '../assests/Star 10.png'
import InVoice from '../assests/Invoice.png'
import Graph from '../assests/Graph.png'
import Dollar from '../assests/cash.png'
import Card from '../assests/Card.png'

import Slider3 from '../assests/slider3.png'


import Slider4 from '../assests/homerankingimageman.png'
import Slider5 from '../assests/slider5.png'
import SelfEmployed from '../assests/selfemployed.png'
import Company from '../assests/company.png'



import FooterSection from "../components/Footer";
import Hero from "../homecomponents/Hero";
import HomeUserInput from "../homecomponents/HomeUserInput";
import ServiceMan from "../homecomponents/ServiceMan";
import Homephone from "../homecomponents/Homephone";
import Playbuttons from "../homecomponents/Playbuttons";
import Homelisting from "../homecomponents/Homelisting";
import Candidates from "../homecomponents/Candidates";
import Professional from "../homecomponents/Professional";
import Contract from "../homecomponents/Contract";
import Developers from "../homecomponents/Developers";
import Users from "../homecomponents/Users";
import Endwords from "../homecomponents/Endwords";
import Design from "../homecomponents/righty";
import CenteredLogoWithParagraphs from "../homecomponents/CenteredLogoWithParagraphs ";
function HomePage() {

  return (
    <div className="bg-[#fff]">
      <NavbarSection />
      <div className="">
        <CenteredLogoWithParagraphs/>
        {/* <Hero /> */}
        {/* <Playbuttons/> */}
        <Homelisting/>
        <Professional/>
        <Contract/>
        <Developers/>
        <Users/>
        <Endwords />
        <Homephone/>
        {/* <HomeUserInput/> */}
        {/* <ServiceMan/> */}
  
       
        
        {/* <Candidates/> */}
       
      
        
       
      </div>
      <FooterSection />

    </div>

  )
}

export default HomePage; 