import React from 'react';

const CommentList = ({ comments }) => {
  if (!Array.isArray(comments) || comments.length === 0) {
    return <p>No comments available</p>;
  }

  return (
    <div className="mt-4">
      {comments.map((comment, index) => (
        <div key={index} className="p-2 border-b">
          <p>{index + 1}. {comment.comment} <br /><span className="text-sm text-gray-500">- {comment.professional_name}</span></p>
        </div>
      ))}
    </div>
  );
};

export default CommentList;
