import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import './user.css';
import LOGO2 from '../../assests/b2bjob logo 1.png';
import LoginVector from '../../assests/loginVector.png';

function LoginPro() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleForgotPasswordClick = () => {
        window.location.href = 'https://btobjob.urbancodez.com/forgot_password_pro.php';
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await api.LoginPro(email, password);
            if (response.redirect) {
                window.location.href = response.redirect; // Redirect to the verification page
            } else if (response.user) {
                localStorage.setItem('userId', response.user.id);
                navigate('/profile/subscription'); // Redirect to the profile page after successful login
            }
        } catch (error) {
            console.error('Login failed', error);
            setError(error.response?.data?.error || 'Login failed. Please check your credentials.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen ">
          <div className="w-96 p-8 rounded-3xl shadow-lg bg-[#E1F5C4]">
            <div className="text-center">
              <img src={LOGO2} alt="Logo" className="mx-auto mb-4" />
            </div>
            <div>
              <h4 className="text-center text-[#75C00A] font-extrabold text-xl">Connexion</h4>
              <p className="text-center text-[#75C00A] text-sm">Nous sommes heureux de vous voir</p>
            </div>
            <form onSubmit={handleLogin}>
              <div className="mt-4">
                <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="email">E-mail</label>
                <input
                  className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
                  type="email"
                  id="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mt-4">
                <label className="block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="password">Passe</label>
                <input
                  className="w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
                  type="password"
                  id="password"
                  placeholder="Tapez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {error && <div className="text-red-500 text-xs mt-2">{error}</div>}
              <div className="flex items-center mt-4">
                <input type="checkbox" id="remember" className="form-checkbox" />
                <label htmlFor="remember" className="ml-2 text-xs text-[#75C00A] font-semibold">Souviens-toi de moi</label>
              </div>
              <div className="mt-4">
                <button type="submit" className="w-full py-2.5 rounded-full bg-gradient-to-r from-[#75C00A] to-[#375A05] text-white font-semibold">
                  Se connecter
                </button>
              </div>
            </form>
            <div className="mt-4 text-center">
            <button
      className="text-xs text-[#75C00A] font-semibold cursor-pointer"
      onClick={handleForgotPasswordClick}
    >
      Mot de passe oublié?
    </button>
              <p className="text-xs text-[#75C00A] font-normal mt-1">or</p>
              <p className="text-xs text-[#65A406] font-normal mt-1">Vous n'avez pas de compte ?</p>
              <button
                className="w-full py-2.5 rounded-full border-2 border-[#75C00A] text-[#65A406] font-semibold mt-2"
                onClick={() => navigate("/professionaluser")}
              >
                <Link className="no-underline" to="/individualsignup">S'inscrire</Link>
              </button>
            </div>
            <div className="mt-4 text-center">
              <p className="text-xs text-[#65A406] font-semibold">As-tu besoin d'aide? Contactez le support technique.</p>
              <div className="flex justify-center items-center mt-2">
                <img src={LoginVector} width={12} alt="Support" />
                <p className="text-xs text-lime-500 ml-2">support@btobjob.com</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    export default LoginPro;
