import React, { useState } from 'react';
import './user.css';
import LOGO from '../../assests/websitelogo.png';
import { Link } from 'react-router-dom';
import ProfessionalUpload from '../../assests/professionalupload.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function ProfessionalSignup() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        kbis: null,
        insurance: null,
        civilLiability: null
    });
    const [isVerifying, setIsVerifying] = useState(false);
 
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };
 
    const handleSubmit = async (e) => {
        e.preventDefault();
 
        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match!');
            return;
        }
 
        setIsVerifying(true); // Set verifying state to true
 
        const form = new FormData();
        form.append('name', formData.name);
        form.append('email', formData.email);
        form.append('phone', formData.phone);
        form.append('password', formData.password);
        // form.append('kbis', formData.kbis);
        // form.append('insurance', formData.insurance);
        // form.append('civil_liability', formData.civilLiability);
 
        try {
            const response = await axios.post('https://btobjob.urbancodez.com/professional-signup.php', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });

            if (response.data.status === "success") {
                alert('Signup successful!');
                localStorage.setItem('email', formData.email);
                window.location.href = 'https://btobjob.urbancodez.com/verify_email.php';
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('There was an error! ' + error.message);
        }
    };
 

   return (
       <div className="flex justify-center items-center min-h-screen shadow-2xl">
           <div className='w-96 p-2 rounded-3xl shadow-lg bg-[#E1F5C4]'>
               <h4 className='individual-heading'>Créer un compte </h4>
               <p className='individual-para'>Nous sommes heureux de vous voir</p>
               <form onSubmit={handleSubmit}>
                   <div>
                       <p className='individual-para-input'>Nom et prénom</p>
                       <div className='individual-input-center'>
                           <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='text' name='name' placeholder='Entrez votre nom complet' onChange={handleChange} required />
                       </div>
                   </div>
                   <div>
                       <p className='individual-para-input'>E-mail</p>
                       <div className='individual-input-center'>
                           <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='email' name='email' placeholder='E-mail' onChange={handleChange} required />
                       </div>
                   </div>
                   <div>
                       <p className='individual-para-input'>Numéro de téléphone</p>
                       <div className='individual-input-center'>
                           <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='text' name='phone' placeholder='Entrez votre numéro' onChange={handleChange} required />
                       </div>
                   </div>
                   <div>
                       <p className='individual-para-input'>Créer un mot de passe</p>
                       <div className='individual-input-center'>
                           <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='password' name='password' placeholder='entrer un nouveau mot de passe' onChange={handleChange} required />
                       </div>
                   </div>
                   <div>
                       <p className='individual-para-input'>Confirmez le mot de passe</p>
                       <div className='individual-input-center'>
                           <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='password' name='confirmPassword' placeholder='Confirmez le mot de passe' onChange={handleChange} required />
                       </div>
                   </div>
                   {/* <div>
                       <p className='individual-para-input'>Téléchargez votre Kbis</p>
                       <div className='professional-input-center'>
                           <div className="professional-input-wrapper">
                               <img src={ProfessionalUpload} width={20} className="professional-upload-icon" />
                               <input className='block w-60 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ms-10' type='file' name='kbis' onChange={handleChange} required />
                           </div>
                       </div>
                   </div>
                   <div>
                       <p className='individual-para-input'> Téléchargez votre Assurance Decennal</p>
                       <div className='professional-input-center'>
                           <div className="professional-input-wrapper">
                               <img src={ProfessionalUpload} width={20} className="professional-upload-icon" />
                               <input className='block w-60 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ms-10' type='file' name='insurance' onChange={handleChange} required />
                           </div>
                       </div>
                   </div> */}
                   
                   <div className='flex px-2 items-center my-2'>
                       <input type="checkbox" required />
                       <p className='ml-2 text-xs text-[#75C00A] font-semibold flex items-center'>J'accepte toutes les déclarations incluses dans les conditions d'utilisation</p>
                   </div>
                   <div className='individual-btn-center'>
                       <button className='sinscrire-individual-btn' type='submit'>{isVerifying ? 'Verifying...' : 'S\'inscrire'}</button>
                   </div>
               </form>
               <p className='individual-or'>or</p>
               <p className='individual-Vous-paragraph-center'>Vous avez déjà un compte</p>
               <div className='individual-btn-center'>
                   <button className='seconnector-individual-btn border-2 border-lime-700'>
                       <Link className='login-link' to="/login-pro">Se connecter</Link>
                   </button>
               </div>
           </div>
       </div>
   );
}

export default ProfessionalSignup;
