import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicRequest from '../RequestPage/PublicRequest';
import api from "../../services/api";

const ServicesAndRental = () => {
    const [rentalEquipmentsData, setRentalEquipmentsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [cityQuery, setCityQuery] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [openForm2, setOpenForm2] = useState(false);

    useEffect(() => {
        const fetchRentals = async (page, type) => {
            setLoading(true);
            setError(null);
            try {
                const data = await api.fetchRentals(page, 10, type); // Use limit as needed
                setRentalEquipmentsData(data.items);
                setTotalPages(data.totalPages);
            } catch (err) {
                console.error('Error fetching rentals:', err);
                setError('Failed to load rentals. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchRentals(currentPage, selectedType);
    }, [currentPage, selectedType]);

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                const data = await api.fetchTypes(); // Fetch distinct types
                setTypes(data);
            } catch (err) {
                console.error('Error fetching types:', err);
                setError('Failed to load types.');
            }
        };

        fetchTypes();
    }, []);

    const getFormState = (state) => {
        setOpenForm2(state);
    };

    const handleCardClick = (userId) => {
        navigate(`/profile/specificUser?professional_id=${userId}`);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const handleCitySearchChange = (e) => {
        setCityQuery(e.target.value.toLowerCase());
    };

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        setCurrentPage(1); // Reset to page 1 when filtering by type
    };

    const getFilteredRentals = () => {
        if (!Array.isArray(rentalEquipmentsData)) {
            return [];
        }

        return rentalEquipmentsData.filter((rental) => {
            const matchesSearchQuery = rental.title?.toLowerCase().includes(searchQuery) || 
                                       rental.type?.toLowerCase().includes(searchQuery) || 
                                       rental.professional_city?.toLowerCase().includes(searchQuery);
            const matchesCityQuery = rental.professional_city?.toLowerCase().includes(cityQuery);
            const matchesType = selectedType ? rental.type === selectedType : true;

            return matchesSearchQuery && matchesCityQuery && matchesType;
        });
    };

    const filteredRentals = getFilteredRentals();

    return (
        <>
            <div className='bg-white rounded-xl max-w-6xl md:mt-16 mt-2 mx-auto'>
                <div className='flex flex-col p-4 md:p-2'>
                    {loading ? (
                        <p>Loading rentals...</p>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : (
                        <>
                            <div className='p-6'>
                                <p className="font-bold text-xl">Location/vente de Véhicules Utilitaires, équipements & Immobilier</p>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 p-4">
                                <input
                                    type="text"
                                    className="w-full text-xs px-2 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Rechercher par titre, type, ville"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <input
                                    type="text"
                                    className="w-full text-xs px-2 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Rechercher par ville seulement"
                                    value={cityQuery}
                                    onChange={handleCitySearchChange}
                                />
                                <select
                                    className="w-full text-xs px-2 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={selectedType}
                                    onChange={handleTypeChange}
                                >
                                    <option value="">All Types</option>
                                    {types.map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-2 py-2 gap-4'>
                                {filteredRentals.map((rental) => (
                                    <div 
                                        key={rental.id} 
                                        className="mx-auto w-full bg-[#e1f5c4] shadow-md rounded-lg overflow-hidden cursor-pointer"
                                        onClick={() => handleCardClick(rental.user_id)}
                                    >
                                        <div className="flex h-[250px]">
                                            <img className=" h-full object-contain w-52" src={rental.image} alt={rental.title} />
                                            <div className="p-1 w-full relative">
                                                <div className="font-semibold text-base">{rental.title}</div>
                                                
                                                <div className="text-gray-600 text-sm font-semibold mt-2">{rental.type}</div>

                                                <div className='absolute bottom-0 p-1'>
                                                    <div className="text-gray-600 text-sm font-semibold">Rent: </div>
                                                    <div className="mt-2">
                                                        <span className="bg-[#65A406] text-white px-2 py-1 rounded-md">€{rental.rent}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-between mt-4'>
                                <button 
                                    onClick={() => handlePageChange(currentPage - 1)} 
                                    disabled={currentPage === 1}
                                    className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'bg-gray-300' : 'bg-lime-500 text-white'} `}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button 
                                    onClick={() => handlePageChange(currentPage + 1)} 
                                    disabled={currentPage === totalPages}
                                    className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'bg-gray-300' : 'bg-lime-500 text-white'} `}
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className='flex justify-center mt-4'>
                <PublicRequest openForm2={openForm2} getFormState={getFormState} />
            </div>
        </>
    );
};

export default ServicesAndRental;
