import React, { useState, useEffect } from 'react';
import { FaMessage } from "react-icons/fa6";
import { HiSignal } from "react-icons/hi2";
import user1 from "../../assests/people4.png";
import user2 from "../../assests/people5.png";
import user3 from "../../assests/people6.png";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import axios from 'axios';


const ForumHome = () => {
  const [topicsMatching, setTopicsMatching] = useState([]);
  const [topicsNotMatching, setTopicsNotMatching] = useState([]);
  const [showAllMatching, setShowAllMatching] = useState(false);
  const [showAllNotMatching, setShowAllNotMatching] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchForumTopics = async () => {
      try {
        const response = await axios.get('https://btobjob.urbancodez.com/fetch-forum-topics-by-id.php', { withCredentials: true });
        setTopicsMatching(response.data.matching || []);
        setTopicsNotMatching(response.data.not_matching || []);
      } catch (error) {
        console.error('Error fetching forum topics:', error);
      }
    };

    fetchForumTopics();
  }, []);

  const AddForum = () => {
    navigate("/profile/addforum");
  };

  const viewTopicDetail = (topicId) => {
    navigate(`/profile/forumdetail/${topicId}`);
  };

  const displayedMatchingTopics = topicsMatching.slice(0, showAllMatching ? topicsMatching.length : 3);
  const displayedNotMatchingTopics = topicsNotMatching.slice(0, showAllNotMatching ? topicsNotMatching.length : 3);

  return (
    <div className="md:mx-auto m-2 max-w-6xl md:mt-12 rounded-lg">
      <div className='flex md:flex-row flex-col-reverse gap-1'>
        <div>
          <div className='flex flex-col items-start gap-4 bg-white rounded-md p-2'>
            <p className='text-2xl font-bold'>Bienvenue!</p>
            <p>Ajoutez un nouveau sujet, discutez-en et partagez également vos opinions avec les autres..</p>
            <button onClick={AddForum} className='px-2 py-2 text-white bg-[#65A406] rounded-md'>Ajouter un nouveau sujet</button>
          </div>
          <div className='bg-white rounded-md p-2 mt-2'>
            <p className='text-2xl font-bold'>Vos sujets</p>
          </div>
          <div>
            {displayedMatchingTopics.map(topic => (
              <div key={topic.id} className='flex flex-row justify-between w-full p-2 bg-white cursor-pointer' onClick={() => viewTopicDetail(topic.id)}>
                <div className='flex items-center gap-2'>
                  <FaMessage className='h-5 w-5 sm:h-7 sm:w-7' />
                  <p className='font-bold text-xs sm:text-base'>{topic.heading}</p>
                </div>
                <div className='flex items-center gap-3'>
                  <div className='flex flex-col items-start'>
                    <p className='sm:text-xs text-[9px]'>{topic.description}</p>
                    <p className='font-bold sm:text-xs text-[9px]'>détails: {topic.replies}</p>
                  </div>
                  <div>
                    <HiSignal color='orange' />
                  </div>
                </div>
              </div>
            ))}
            {topicsMatching.length > 3 && !showAllMatching && (
              <button onClick={() => setShowAllMatching(true)} className='px-2 py-2 text-white bg-[#65A406] rounded-md mt-2'>Voir Tout</button>
            )}
          </div>
        </div>
        </div>
        {/* Autres sujets */}
        <div className='flex flex-col gap-1'>
          <div className='bg-white rounded-md p-2 mt-2'>
            <p className='text-2xl font-bold'>Autres sujets</p>
          </div>
          <div>
            {displayedNotMatchingTopics.map(topic => (
              <div key={topic.id} className='flex flex-row justify-between w-full p-2 bg-white cursor-pointer' onClick={() => viewTopicDetail(topic.id)}>
                <div className='flex items-center gap-2'>
                  <FaMessage className='h-5 w-5 sm:h-7 sm:w-7' />
                  <p className='font-bold text-xs sm:text-base'>{topic.heading}</p>
                </div>
                <div className='flex items-center gap-3'>
                  <div className='flex flex-col items-start'>
                    <p className='sm:text-xs text-[9px]'>{topic.description}</p>
                    <p className='font-bold sm:text-xs text-[9px]'>détails: {topic.replies}</p>
                  </div>
                  <div>
                    <HiSignal color='orange' />
                  </div>
                </div>
              </div>
            ))}
            {topicsNotMatching.length > 3 && !showAllNotMatching && (
              <button onClick={() => setShowAllNotMatching(true)} className='px-2 py-2 text-white bg-[#65A406] rounded-md mt-2'>Voir Tout</button>
            )}
          </div>
        </div>
      </div>
    
  );
};

export default ForumHome;