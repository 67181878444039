import React, { useEffect, useState } from 'react';
import User2 from "../../assests/samuels.png";
import User from "../../assests/people12 - Copy.png";
import User3 from "../../assests/people2.png";
import { FaArrowLeft } from "react-icons/fa";
import room from "../../assests/roomimage.jpg";
import { useNavigate, useLocation } from 'react-router-dom';
import { RxCrossCircled } from "react-icons/rx";
import PrivateRequestForm from './PrivateRequestForm';
import axios from 'axios'
const Allthemes2 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [cardData, setCardData] = useState([]);

    const NavigateToBack = () => {
        navigate(-1); // Navigates back one step in the history stack
    };

    const handleSave = (consultant) => {
        if (selectedUsers.some(user => user.name === consultant.name)) {
            setShowPopup(true);
        } else {
            setSelectedUsers((prevSelected) => [...prevSelected, consultant]);
        }
    };

    const handleRemove = (indexToRemove) => {
        setSelectedUsers((prevSelected) => prevSelected.filter((_, index) => index !== indexToRemove));
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const category = params.get('category');

        axios.get(`https://btobjob.urbancodez.com/get-offers-by-cat.php?category=${category}`)
            .then(response => {
                setCardData(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, [location]);

    return (
        <>
            <div className="mx-auto md:max-w-6xl lg:max-w-full h-full flex flex-col md:flex-row md:gap-1 p-4">
                {showPopup && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-4 rounded-lg shadow- flex items-center gap-2">
                            <p className="text-gray-700 text-xl">User is already saved.</p>
                            <button
                                className="mt-2 bg-lime-500 text-white px-2 py-1 rounded-md"
                                onClick={() => setShowPopup(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
                {/* Left */}
                <div className='flex flex-col bg-white rounded-lg mb-3 sm:mb-0 w-full'>
                    <div className='p-6 border-b-2 flex flex-col'>
                        <div onClick={NavigateToBack} className='flex items-center cursor-pointer'>
                            <FaArrowLeft className='h-6 w-6 pr-2' />
                            <p className="text-xl font-semibold">Tous les themes</p>
                        </div>
                        <p className='text-xs'>Gagnez du temps et postez vos demandes en un clic</p>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
                        {cardData.map((consultant, index) => (
                            <Card key={index} consultant={consultant} onSave={() => handleSave(consultant)} />
                        ))}
                    </div>
                </div>
            </div>
            <PrivateRequestForm boolean={openForm} />
        </>
    );
};

const Card = ({ consultant, onSave }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const imageUrl = `https://btobjob.urbancodez.com/${consultant.image}`;
    const handleNavigateToProfile = () => {
        navigate(`/profile/specificUser?professional_id=${consultant.professional_id}`);
    };

    const handleOpenForm = () => {
        setOpenForm(true);
    };

    return (
        <div className="w-96 bg-white rounded-2xl overflow-hidden shadow-2xl border-2">
            <div className="p-4">
                <div className="flex items-center">
                    <img className="w-12 h-12 rounded-full" src={consultant.professional_Picture} alt="Profile" />
                    <div className="ml-4">
                        <div className="text-gray-600">{consultant.professional_phone}</div>
                        <div className="text-xl semibold">{consultant.professional_name}</div>
                    </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                    <div className="text-lg font-semibold">{consultant.role}</div>
                    <div className="text-gray-600">{consultant.replies}</div>
                </div>
                <div className="mt-4">
                    <img className="w-full h-48 object-cover" src={imageUrl} alt="Consultant" />
                </div>
                <div className="mt-4 text-gray-700">{consultant.description}</div>
                <div className="mt-4 flex justify-between">
                    <button onClick={handleOpenForm} className="bg-[#65A406] text-white px-4 py-2 rounded-md">
                        Demande privée
                    </button>
                    <button onClick={handleNavigateToProfile} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">
                        View Profile
                    </button>
                </div>
            </div>
            {openForm && <PrivateRequestForm boolean={true} professionalId={consultant.professional_id} />}
            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="bg-white p-6 rounded-lg">
                        <p>Demande privée envoyée avec succès!</p>
                        <button onClick={() => setShowPopup(false)} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md">Fermer</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Allthemes2;