import React from 'react'
import { TiTickOutline } from "react-icons/ti";
import ListingImage from '../assests/newsectionimage.png'
import WrongEllipse from '../assests/wronghomeellipse.png'
import RightEllipse from '../assests/righthomeellipse.png'
import btnArrow from '../assests/inputbtnarrow.png'
import { useNavigate } from 'react-router-dom';
import bg from "../assests/rectangle41.png"
import bg2 from "../assests/rectangle40.png"
const Homelisting = () => {
  const navigate = useNavigate();


  const NavigateToChoice = () => {
    navigate("/professionaluser");
  };

  return (
    <div className=' bg-[#FFF] z-10 mb-16 relative mt-20'>
      <div className="grid grid-cols-1 sm:grid-cols-2 items-center  py-10">
        <div className="above-listing-section">
          <h2 className="heading-les-travaux-en text-2xl sm:text-[40px] sm:leading-[60px] leading-[30px] font-semibold">
            Trouves  <span className="span-les-travaux-en"> le professionnel  </span>
            <br />
            dont tu as besoin !
          </h2>

          <p className="para-les-travaux-en"> Bienvenue sur notre plateforme, votre destination ultime pour trouver et
            <br />
            embaucher des professionnels qualifiés pour tous vos besoins en services.
            <br />
            Que vous recherchiez un plombier, un architecte, un électricien, un mécanicien et un sécréteur ou tout autre
            <br />
            expert, nous avons la solution parfaite pour vous</p>

          <ul className=" ml-11 mt-4">
            <li className='flex items-start my-1'>


              <TiTickOutline className="tick-icon" />
              <span className="tick-icon-text">Trouvez les professionnels souhaités en quelques minutes.</span>
            </li>

            <li className='flex items-start my-1'>


              <TiTickOutline className="tick-icon" />
              <span className="tick-icon-text">Équipe de support client dédiée 24h/24 et 7j/7.</span>
            </li>
            <li className='flex items-start my-1'>


              <TiTickOutline className="tick-icon" />
              <span className="tick-icon-text">Protection anti-fraude.</span>
            </li>
          </ul>
          <div className='mt-4'>
            {/* <button  className="btn-Commencer"> Commencer
              <img className="inputbtn-Arrow" src={btnArrow} width={17} />
            </button> */}
            <button className="bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center ml-11 mt-2" onClick={NavigateToChoice}>
              Se connecter
              <img className="ml-2" src={btnArrow} width={17} alt="arrow icon" />
            </button>
          </div>
        </div>
        <div className="mx-auto sm:mt-0 mt-10">
          <img src={RightEllipse} width={40} className="right-ellipse" />
          <img src={WrongEllipse} width={40} className="wrong-ellipse" />
          <img src={ListingImage} width={200} className="listing-main-iamge" />
        </div>
      </div>
      {/* <img className='absolute w-full md:h-[40%] h-[10%] -bottom-14 -z-10' src={bg}/>
      <img className='absolute w-full md:h-[40%] h-[10%] -bottom-28 -z-10' src={bg2}/>


      <img className='absolute w-full md:h-[40%] h-[10%]  -top-32 -z-10 ' src={bg2}/>
      <img className='absolute w-full md:h-[40%] h-[10%]  -top-44 -z-10 ' src={bg}/> */}
    </div>
  )
}

export default Homelisting
