import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const Verifyme = () => {
  const documents = [
    "Cartes d'identité émises par le gouvernement : telles que permis de conduire, passeport, carte d'identité nationale ou tout autre document d'identification officiel.",
    "Factures de services publics : en indiquant votre nom et votre adresse, pour confirmer la résidence.",
    "Relevés bancaires : confirmant vos informations financières et votre adresse.",
    "Carte de sécurité sociale : Dans certains cas, pour vérifier votre numéro de sécurité sociale.",
    "Acte de naissance : Fournir une preuve d'âge et d'identité.",
  ];
  const navigate = useNavigate();
    const NavigateToBack = () => {
        navigate(-1); // Navigates back one step in the history stack
    };
  return (
    <div className="mx-auto md:max-w-3xl lg:max-w-4xl bg-white rounded-lg md:mt-16 md:px-0 px-2">
      <div className="flex flex-col">
        <div className="p-6 border-b-2 flex flex-col">
          <div onClick={NavigateToBack} className="flex items-center">
            <FaArrowLeft className="h-6 w-6 pr-2" />
            <p className="text-xl font-semibold">Vérifier mon identité</p>
          </div>
        </div>
        <div className="flex flex-col w-full gap-6 p-6">
          <div>
            <p>
              ""Vérifiez votre identité pour garantir une expérience sécurisée
              et digne de confiance sur notre plateforme. Votre vie privée est
              primordiale et toutes les informations fournies sont traitées avec
              la plus stricte confidentialité. Merci de nous aider à maintenir
              un environnement sûr pour tous les utilisateurs."
            </p>
            <ul className="list-inside list-decimal space-y-2">
              {documents.map((document, index) => (
                <li key={index} className="text-gray-700">
                  {document}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <ul>live</ul>
          </div>
          <div className="flex flex-col">
            <p className="text-xl font-semibold">Documents à télécharger</p>
            <div className="grid grid-cols-1 md:grid-cols-2 max-w-[550px] gap-3">
              <div className="w-full">
                <p className="text-gray-500">Téléchargez votre photo récente</p>
                <label
                  for="doc"
                  class="flex items-center gap-3 p-2 justify-center rounded-xl border border-gray-300 bg-gray-50 cursor-pointer"
                >
                  <MdOutlineFileUpload color="#65A406" size={20} alt="" />

                  <div class="">
                    <h4 class="text-base font-normal text-[#65A406]">
                      Your Picture
                    </h4>
                  </div>
                  <input type="file" id="doc" name="doc" hidden />
                </label>
              </div>
            
             
              <div className="w-full">
                <p className="text-gray-500">Téléchargez vos factures de </p>
                <label
                  for="doc"
                  class="flex items-center gap-3 p-2 justify-center rounded-xl border border-gray-300 bg-gray-50 cursor-pointer"
                >
                  <MdOutlineFileUpload color="#65A406" size={20} alt="" />

                  <div class="">
                    <h4 class="text-base font-normal text-[#65A406]">
                      Utility Bills
                    </h4>
                  </div>
                  <input type="file" id="doc" name="doc" hidden />
                </label>
              </div>
              <div className="w-full">
                <p className="text-gray-500">Téléchargez votre carte</p>
                <label
                  for="doc"
                  class="flex items-center gap-3 p-2 justify-center rounded-xl border border-gray-300 bg-gray-50 cursor-pointer"
                >
                  <MdOutlineFileUpload color="#65A406" size={20} alt="" />

                  <div class="">
                    <h4 class="text-base font-normal text-[#65A406]">
                      ID Card
                    </h4>
                  </div>
                  <input type="file" id="doc" name="doc" hidden />
                </label>
              </div>
             
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mb-20">
          <button onClick={NavigateToBack} className="w-[200px] rounded-xl bg-gradient-to-r text-white from-lime-400 to-lime-600 px-2 py-2 flex items-center justify-center">
            {" "}
            Vérifier mon identité
          </button>
        </div>
      </div>
    </div>
  );
};

export default Verifyme;
